<template>	
	<article v-if="false !== activity && Object.values( activity ).length > 0" :class="'activity-' + activity.activityType + typeof background !== 'undefined' ? ' ' + background : ' bg-brand-accent'" class="activity mb-4 weight-bold color-mono-lightest border-radius__100 px-4 py-3">
		<router-link class="weight-bold row justify-content-md-between no-gutters align-items-center" :to="{ name: 'Activity', params: { 'type': activity.activityType, 'id': activity.id  } }">
			<div class="col-auto">
				<img v-if="typeof background !== 'undefined' && 'bg-brand-accent' === background" class="activity-icon" :src="getActivityAsset( activity.activityType, 'white' )" role="presentation">
				<img v-else class="activity-icon" :src="getActivityAsset( activity.activityType )" role="presentation">
			</div>

			<span class="col activity-name" v-html="pre + ' ' + $functions.sentenceCase( activity.activityType )"></span>
			<span class="activity-arrow col-auto">&rarr;</span>
		</router-link>
	</article>
</template>	

<script>
	export default {
		name: 'ActivityComp',

		props: [ 'pre', 'data', 'attribute', 'background' ],

		data() {
			return {
				activity: [],
				activityType: false,
				assets: {
					white: [],
					black: []
				}
			}
		},

		mounted() {
			/*
			check for emails and hide them
			 */
			if ( typeof this.data.email === 'undefined' ) {
				this.activity = this.data;

				/*
				get activityType
				 */
				this.activity.meta = this.activity[ this.$functions.camelCase( this.activity.activityType, false ) ];
			} else {
				this.activity = false;
			}

			// svgs
			let white_svgs = require.context('@/assets/images/svgs/ui/white', false, /\.svg$/);
			white_svgs = white_svgs.keys().map(white_svgs);
			
			white_svgs.forEach( svg => {
				let key = svg.replaceAll( '/img/', '' );
				key = key.substring( 0, key.indexOf('.') );

				if ( typeof this.assets['white'][ key ] === 'undefined' ) {
					this.assets['white'][ key ] = '';
				}

				this.assets['white'][ key ] = svg;
			} );

			// svgs
			let black_svgs = require.context('@/assets/images/svgs/ui/', false, /\.svg$/);
			black_svgs = black_svgs.keys().map(black_svgs);
			
			black_svgs.forEach( svg => {
				let key = svg.replaceAll( '/img/', '' );
				key = key.substring( 0, key.indexOf('.') );

				if ( typeof this.assets['black'][ key ] === 'undefined' ) {
					this.assets['black'][ key ] = '';
				}

				this.assets['black'][ key ] = svg;
			} );
		},

		methods: {
			getActivityAsset: function( type = null, colour = 'black' ) {
				let asset = Object.keys( this.assets[ colour ] ).find( asset => type.indexOf( asset ) >= 0 );
				return this.assets[ colour ][ asset ];
			}
		}
	}
</script>

<style lang="scss" scoped>
	.activity {
		&.bg-brand-secondary {
			color: color( mono, darkest );
		}

		a {
			color: inherit;
		}

		&-arrow {
			width: 40px;
			text-align: right;
		}

		svg,
		&-icon {
			width: 15px;
			height: auto;
			margin-right: $spacer / 1.5;
		}

		&-name {
			text-transform: capitalize;
		}
	}
</style>