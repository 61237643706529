<template>
	<section class="activity">
		<div v-if="false !== component" >
			<component :is="component" :data="data" />
		</div>

		<Error v-else />
	</section>
</template>

<script>
	export default {
		name: 'Activity',
		
		data() {
			return {
				activity: false,
				component: false,
			}
		},

		mounted() {
			if ( this.$store.getters.journey.allActivities[ this.$route.params.type ] ) {
				this.data = this.$store.getters.journey.allActivities[ this.$route.params.type ].find( activity => activity.id === JSON.parse( this.$route.params.id ) );

				this.component = this.$functions.camelCase( this.$route.params.type, true );
			}
		}
	}
</script>