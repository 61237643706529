<template>
	<section id="success" v-show="false !== data" class="success full-screen bg-brand-accent fadeIn">
		<div class="row no-gutters align-items-center">
			<div class="container px-5 h-auto">
				<div class="success-col fadeUp">
					<p v-if="data.title" class="font-large family-heading color-mono-lightest weight-bold mb-5 align-center mb-2" v-html="data.title" />
					<p v-if="data.sub_title" class="font-lead color-mono-lightest weight-bold" v-html="data.sub_title" />
					<p v-if="data.body" class="color-mono-lightest weight-bold mb-7" v-html="data.body" />

					<router-link class="button button-ui bg-brand-secondary color-mono-darkest" v-if="typeof data.redirect !== 'undefined'" :to="{ 'name': $store.getters.referrer }">Continue &rarr;</router-link>

					<button v-else @click.prevent="destroy" class="button button-ui bg-brand-secondary color-mono-darkest">Continue &rarr;</button>
				</div>
			</div>
		</div>

		<I viewBox="0 0 819.13 590.44" class="theme-i fill-mono-lightest" />
	</section>
</template>

<script>
	import I from '@/assets/images/svgs/miop-i.svg?inline';

	export default {
		name: 'Success',

		data() {
			return {
				data: false
			}
		},

		components: {
			I
		},

		watch: {
			'$store.state.success': function( success ) {
				/*
				hide/fail silently if no success data
				 */
				if ( false === success || typeof success === 'undefined' ) {
					this.data = false;
					return;
				}

				/*
				if string / not JSON 
				 */
				
				if ( typeof success === 'string' ) {
					success = JSON.parse( success );
				}

				/*
				remove classes/flicker
				 */
				document.getElementById('success').classList.remove('fadeOut');
				document.getElementById('success').querySelector('.success-col').classList.remove('fadeDown');

				/*
				update data
				 */
				this.data = success;
			},
		},

		methods: {
			destroy: function() {
				document.getElementById('success').classList.add('fadeOut');
				document.getElementById('success').querySelector('.success-col').classList.add('fadeDown');

				setTimeout( () => {
					this.$store.commit( 'success', false );
					this.data = false;
				}, 500 );
			}
		}
	}
</script>

<style lang="scss" scoped>
	.success-col {
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;
	}
</style>