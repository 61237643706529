<template>
	<section class="quick-navigation bg-mono-lightest p-4 p-lg-5">
		<div class="row align-items-center justify-content-between">
			<div class="col-auto">
				<button @click.prevent="$router.go(-1)" class="button button-svg p-0">
					<Back class="fill-brand-accent" />
				</button>
			</div>

			<div class="col-auto">
				<router-link :to="{ name: 'Home' }" class="button button-svg">
					<Circle class="fill-brand-secondary" />
				</router-link>
			</div>

			<div class="col-auto">
				<router-link :to="{ name: 'Profile' }" class="button button-svg">
					<User class="fill-brand-accent" />
				</router-link>
			</div>
		</div>
	</section>
</template>

<script>
	import Back from '@/assets/images/svgs/ui/back.svg?inline';
	import Circle from '@/assets/images/svgs/ui/circle.svg?inline';
	import User from '@/assets/images/svgs/ui/user.svg?inline';

	export default {
		name: 'QuickNavigation',

		components: {
			Back,
			Circle,
			User
		}
	}
</script>

<style lang="scss" scoped>
	.quick-navigation {
		position: fixed;
		z-index: 1;
		left: 0;
		bottom: 0;
		width: 100%;
		border-top: 1px solid color( mono, lighter );

		svg {
			width: 40px;
		}

		@include media-breakpoint-up(md) {
			display: none;
		}
	}
</style>