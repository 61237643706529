import { createApp } from 'vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import App from './App.vue'
import VueProgressBar from "@aacassandra/vue3-progressbar";

/*
create the app
 */
const app = createApp(App);

app.use(store);
app.use(router);

const options = {
    color: "#ffffff",
    failedColor: "#fb8383",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    position: "absolute",
    inverse: false,
};

app.use(VueProgressBar, options)

/*
components
 */
import components from '@/components';

Object.values( components ).forEach( component => {
	app.component(component.name, component);
} );

/*
views
 */
import views from '@/views';

Object.values( views ).forEach( component => {
	app.component(component.name, component);
} );

/*
axios
 */
import axios from 'axios'
import vue_axios from 'vue-axios'
app.use( vue_axios, axios );
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

/*
moment
 */
import moment from 'moment'
app.use( moment );

/*
functions
 */

app.config.globalProperties.$functions = {
    userIsLoggedIn: function() {
        return Object.values( store.getters.user ).length > 0 && typeof store.getters.user.firstName !== 'undefined' ? true : false;
    },
    isLoading: function() {
        if ( typeof store.getters.loading === 'undefined' ) {
            return false;
        }

        return store.getters.loading;
    },
    isError: function() {
        if ( typeof store.getters.error === 'undefined' ) {
            return false;
        }

        return store.getters.error;
    },
    getJourney: function() {
        if ( typeof store.getters.journey === 'undefined' || Object.values( store.getters.journey ).length === 0 ) {
            return false;
        }

        return store.getters.journey;
    },
    initApp: function() {
        // app only, on appResumed, do stuff.
        document.addEventListener("appResumed", () => {
            store.dispatch( 'journey_timestamp_check' );
            store.dispatch( 'addToLog', 'Using the appResumed event to prompt app to auto-reload' );
        });

        // app only, on appResumed, do stuff.
        document.addEventListener("appPaused", () => {
            store.dispatch( 'journey_timestamp_check' );
            store.dispatch( 'addToLog', 'Using the appPaused event to prompt app to auto-reload' );
        });
    },
    titleCase: function( data ) {
        return data.charAt(0).toUpperCase() + data.substr(1).toLowerCase();
    },

    sentenceCase: function( data ) {
        return data.replaceAll('_', ' ');
    },

    sanitizeString: function( string ) {
        string = string.replaceAll(/[^a-zA-Z ]/g, '');
        return string.replaceAll(',', '').replaceAll(' ', '_').toLowerCase();
    },

    camelCase: function( string, titleCase ) {
        if ( ! string ) {
            return;
        }

        let parts = string.split( '_' );

        if ( parts.length === 1 ) {
            if ( true === titleCase ) {
                string = this.titleCase( string );
            }

            return string;
        }

        let new_parts = parts.map( ( part, index = false ) => {
            if ( true === titleCase ) {
                part = this.titleCase( part );
                return part;
            } else {
                if ( index > 0 ) {
                    part = this.titleCase( part );
                    return part;
                } else {
                    return part;
                }
            }
        } );

        // return joined array.
        if ( Object.values( new_parts ).length > 0 ) {            
            string = new_parts.join('');
        }

        return string;
    },

    isFuture: function( date ) {
        let future = moment( date ).isAfter( moment() );

        return future;
    },

    isToday: function( date ) {
        let today = moment( date ).isSame( moment(), 'day' );

        return today;
    },

    isPast: function( date ) {
        let future = moment().isAfter( moment( date ) );

        return future;
    },

    daysSinceOrUntil: function( date ) {
        let hours = moment().diff( moment( date ), 'hours' ).toString().replaceAll( '-', '' ); 
        let days = Math.round( ( hours / 24 ) )
        let future = moment( date ).isAfter( moment() );

        // if it's in the past, but under 24hours ago, then it's "today"
        if ( false === future ) {
            if ( parseInt( hours ) < 24 ) {
                return 0;
            }

            if ( hours <= days * 24 ) {
                return days - 1;
            }

            return -days;
        }

        // if it's in the future, but under 24hours to go, then it's "today"
        if ( true === future ) {
            if ( parseInt( days ) <= 0 && parseInt( hours ) < 24 ) {
                return 1;
            }

            if ( hours >= days * 24 ) {
                return days + 1
            }

            return days;
        }

        // if all else fails, return the original moment string
        return moment().diff( moment( date ), 'days' ).toString().replaceAll( '-', '' );
    },

    formatDate: function( date ) {
        return moment( date ).format('Do MMM YYYY');
    },
}

/*
svgs?
 */

// let svgs = require.context('@/assets/images/svgs/ui', false, /\.svg/);
// svgs = svgs.keys().map(svgs);

// svgs.forEach( svg => {
//     let name = svg.replace( '/img/', '' );
//     let slug = name.substring( 0, name.indexOf('.') );
//     let parts = [];

//     slug.split('-').some( part => {
//         part = part.charAt(0).toUpperCase() + part.substr(1).toLowerCase();
//         parts.push( part );
//     } );  

//     app.component( parts.join(''), svg );
// } );

/*
mount
 */
app.mount('#app');
