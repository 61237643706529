<template>
	<footer class="mastfoot p-4 p-lg-5 font-sub border-top">
		<hr>
		
		<div class="row align-items-center color-mono-dark justify-content-between">
			<div class="col-12 col-lg-auto row no-gutters align-items-center mb-3 mb-lg-0">
				<p class="mb-0 mr-4">&copy; MiOperation <span v-html="new Date().getFullYear()"></span> | Built collaboratively by <a target="_blank" rel="nofollow" href="https://echodek.co/">EchoDek</a> &amp; <a target="_blank" rel="nofollow" href="https://www.kaluna.co.uk">Kaluna</a></p>
			</div>

			<div class="col-12 col-lg-auto">
				<ul class="mastfoot-nav row no-gutters nbs">
					<li class="mr-4"><router-link :to="{ 'path': '/privacy-policy' }">Privacy Policy</router-link></li>
					<li><router-link :to="{ 'name': 'Terms' }">Terms &amp; Conditions</router-link></li>
				</ul>
			</div>	
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
	}
</script>

<style lang="scss" scoped>
	.mastfoot {
		margin-bottom: 75px;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}

		&-logo {
			width: 90px;
		}
	}
</style>