<template>
	<section v-if="'/' === this.$route.path" class="welcome bg-brand-accent color-mono-lightest">
		<div class="container">
			<div class="row m-0 py-7 welcome-row align-items-start">
				<div class="welcome-header">
					<h1 class="color-brand-secondary font-largest">Welcome</h1>			
					<p class="font-mid weight-bold">New here? Please register using the code provided by your surgeon, alternatively - please log in.</p>
				</div>

				<div class="welcome-actions mt-auto">
					<div class="row">
						<div class="col-auto">
							<router-link class="button button-ui bg-mono-lightest" :to="{ name: 'Login' }">Login to MiOP</router-link>
						</div>

						<div class="col-12 mt-5">
							<div class="row no-gutters">
								<router-link class="color-mono-lightest" :to="{ path: '/forgot-password' }">Forgotten your password?</router-link>

								<router-link class="ml-5 color-mono-lightest" :to="{ path: '/privacy-policy' }">Privacy Policy</router-link>

								<router-link class="ml-5 color-mono-lightest" :to="{ path: '/terms-conditions' }">Terms & Conditions</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<MiopI viewBox="0 0 819.13 590.44" class="theme-i fill-mono-lightest" />
	</section>

	<router-view v-else/>
</template>

<script>
	import MiopI from '@/assets/images/svgs/miop-i.svg?inline';
	
	export default {
		name: 'Welcome',

		components: {
			MiopI
		}
	}
</script>

<style lang="scss" scoped>
	.welcome {
		height: 100vh;

		&-row {
			height: 100vh;
		}
	}
</style>