<template>
	<section class="education py-5 py-md-7">
		<div class="container">
			<div class="row no-gutters justify-content-between mb-5 align-items-center">
				<h1 class="color-brand-accent font-largest weight-bold">Education</h1>

				<div>
					<button @click.prevent="$router.go(-1)" class="button button-ui button-min bg-brand-secondary color-mono-darkest">Go back</button>
				</div>
			</div>		

			<div v-if="null === view" class="row education-tiles justify-content-start mt-5">
				<div class="col-6">
					<router-link :to="{ name: 'Videos' }" class="education-tile align-center border-radius__20 p-5 box-shadow__light">
						<div>
							<Video class="fill-brand-accent mb-3 mx-auto" />
						</div>

						<div>
							<span class="family-heading color-mono-darkest font-reset align-center mb-0">Videos</span>
						</div>
					</router-link> 
				</div>

				<div class="col-6">
					<router-link :to="{ name: 'Articles' }" class="education-tile align-center border-radius__20 p-5 box-shadow__light">
						<div>
							<File class="fill-brand-accent mb-3 mx-auto" />
						</div>

						<div>
							<span class="family-heading color-mono-darkest font-reset align-center mb-0">Articles</span>
						</div>
					</router-link> 
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import File from '@/assets/images/svgs/ui/file.svg?inline';
	import Video from '@/assets/images/svgs/ui/video.svg?inline';

	export default {
		name: 'Education',

		data() {
			return {
				base: process.env.VUE_APP_BASE,
				articles: false,
				view: null,
				flickityOptions: {
					prevNextButtons: false,
					pageDots: true,
					wrapAround: true,
					adaptiveHeight: true,
					imagesLoaded: true
				}
			}
		},

		components: {
			File,
			Video
		},

		mounted() {
			this.axios.get( 
				'/articles',
                {
                    auth: {
                        username: this.$store.getters.user.firstName,
                        password: this.$store.getters.token
                    }
                },
			)
			.then( response => {
				if ( 200 === response.status ) {
					this.articles = [];
					this.articles = response.data.articles;
					this.$store.commit( 'articles', this.articles );
				}
			} )
			.catch( error => {
				console.log( error );
			} );
		}
	}
</script>

<style lang="scss" scoped>
	.education-tile {
		border: 1px solid color( mono, light );
		width: 100%;
		display: block;

		svg {
			width: auto;
			height: 50px;
		}
	}

	article {
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-up(md) {
			max-width: 860px;
		}

		img {
			width: 100%;
		}
	}
</style>