<template>
	<section v-if="Object.values( shortcuts ).length > 0" class="shortcuts py-7 bg-mono-lightest border-top">
		<div class="container">
			<hr> 
			<h3 class="font-mid mb-5">Shortcuts</h3>
			
			<flickity @init="setupFlickity" ref="shortcuts" class="shortcuts shortcuts-row" :options="flickityOptions">
				<div class="carousel-cell mx-0 mb-md-5 col-xl-4 shortcut align-center p-0 pr-5" v-for="( shortcut, index ) in shortcuts" :key="index">
					<article class="shortcut-item mr-0 border-radius__20 p-5" :class="typeof shortcut.meta.active === 'undefined' || true === shortcut.meta.active ? 'active' : 'inactive'">
						<router-link :title="'Go to ' + shortcut.name" v-if="typeof shortcut.meta.active === 'undefined' || true === shortcut.meta.active" :to="{ path: shortcut.path }">
							<img class="mb-4" :src="require( '@/assets/images/svgs/ui/' + shortcut.name.toLowerCase() + '.svg' )" role="presentation">

							<span class="font-mid" v-html="shortcut.name"></span>
						</router-link>

						<div v-else>
							<img class="mb-4" :src="require( '@/assets/images/svgs/ui/' + shortcut.name.toLowerCase() + '.svg' )" role="presentation">

							<span class="font-mid weight-bold" v-html="shortcut.name"></span>
						</div>
					</article>
				</div>
			</flickity>
		</div>
	</section>
</template>

<script>
	import _ from 'lodash';
	import Flickity from 'vue-flickity';
	
	export default {
		name: 'Shortcuts',

		components: {
			Flickity,
		},

		data() {
			return {
				shortcuts: [],
				scrollDetect: null,
				scrollLeft: null,
				flickityOptions: {
					prevNextButtons: false,
					pageDots: false,
					wrapAround: false,
					adaptiveHeight: true,
					imagesLoaded: true,
					cellAlign: 'left',
					watchCSS: true
				}
			}
		},

		mounted() {
			/*
			make sure we have some links to loop through
			 */
				
			if ( Object.values( this.$router.options.routes ).length > 0 ) {
				this.shortcuts = this.$router.options.routes.filter( route => typeof route.meta !== 'undefined' && ( typeof route.meta.shortcut !== 'undefined' && true === route.meta.shortcut ) );
				this.shortcuts = _.orderBy( this.shortcuts, [ data => data.meta.active ], 'desc' );
			}
		},

		methods: {
			setupFlickity: function() {
				if ( this.$refs.shortcuts.$flickity ) {
					if ( this.$refs.shortcuts.$flickity.element.querySelector('.flickity-viewport') ) {
						this.$refs.shortcuts.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.shortcuts.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
					}

					this.$refs.shortcuts.on( 'change', () => {
						this.$refs.shortcuts.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.shortcuts.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
					});

					this.$refs.shortcuts.on( 'dragEnd', () => {
						this.$refs.shortcuts.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.shortcuts.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
					});
				}
			},

			excerpt( words, length = 100 ) {
				words = words.substring( 0, length );
				return words.substr( 0, Math.min( words.length, words.lastIndexOf(" ") ) ) + '...';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.shortcut {
		max-width: 280px;

		&-item {
			margin-right: 0; 

			a {
				color: inherit;
				text-decoration: none;

				span {
					display: block;
				}
			}

			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
				width: auto;
				height: 80px;
			}

			&.active {
				&:hover {
					box-shadow: $box-shadow__light;
				}
			}

			&.inactive {
				opacity: 0.5;
			}
		}

		&:nth-of-type(odd) article {
			background-color: color( brand, accent );
		}

		&:nth-of-type(even) article {
			background-color: color( brand, secondary );
		}
	}

	.shortcuts:after {
		content: 'flickity';
		display: none;
	}

	@include media-breakpoint-up(lg) {
		.shortcuts:after {
			content: '';
		}

		.shortcuts-row { 
			display: flex;
			flex-wrap: wrap;
		}
	}
</style>