<template>
	<section class="assessments py-5 py-md-7">
		<div class="container">
			<div class="row no-gutters justify-content-between mb-5 align-items-center">
				<h1 class="color-brand-accent font-largest weight-bold">Assessments</h1>

				<div>
					<button @click.prevent="$router.go(-1)" class="button button-ui button-min bg-brand-secondary color-mono-darkest">Go back</button>
				</div>
			</div>

			<div v-if="false !== assessments || Object.values( assessments ).length >= 0">
				<ul class="nbs">
					<li v-for="( assessment, index ) in assessments" :key="index" class="mb-5">
						<div class="assessment-row row align-items-center justify-content-between">
							<div class="col">
								<h3 class="font-mid color-mono-darkest" v-html="getAttribute( assessment, 'title' )"></h3>

								<div v-if="typeof assessment.completedAt !== 'undefined' && null !== assessment.completedAt">
									<span v-html="'Completed on ' + getCompletedDate( assessment.completedAt )"></span>
								</div>

								<div v-else>
									<div class="row no-gutters align-items-center assessment-meta">
										<!-- today -->
										<span v-if="true === $functions.isToday( assessment.startsOn )" v-html="'Available now'"></span>

										<!-- available in -->
										<span v-else-if="1 === $functions.daysSinceOrUntil( assessment.startsOn )" v-html="'Available tomorrow'"></span>

										<!-- else -->
										<span v-else v-html="'Available in ' + $functions.daysSinceOrUntil( assessment.startsOn ) + ' days'"></span>

										<span v-html="$functions.formatDate( assessment.startsOn )"></span>
									</div>
								</div>
							</div>

							<div class="col-auto">
								<router-link  v-if="true === $functions.isToday( assessment.startsOn )"  class="button button-ui bg-brand-accent color-mono-lightest" :to="{ name: 'Assessment', params: { 'attribute': 'futureActivities', 'id': assessment.id  } }" v-html="'View'" />
							</div>
						</div>
					</li>
				</ul>
			</div>

			<p v-if="Object.values( assessments ).length === 0" class="alert alert-danger">No assessments are currently available for you right now.</p>
		</div>
	</section>
</template>

<script>
	import _ from 'lodash';

	export default {
		name: 'Assessments',

		data() {
			return {
				assessments: false
			}
		},

		mounted() {
			this.axios.get( 
				'/activities?activity_type=assessments',
				{
					auth: {
						username: this.$store.getters.user.firstName,
						password: this.$store.getters.token
					}
				},
			)
			.then( response => {
				if ( 200 === response.status ) {
					this.assessments = _.orderBy( response.data.activites, ( assessment ) => parseInt( this.getStartLeadTime( assessment.startsOn ).length ) , 'asc' )
				}
			} )
			.catch ( error => {
				console.log( error );
			} );
		},

		methods: {
			getAttribute: function( activity, attribute ) {
				return activity['assessment'][ attribute ];
			},

			getCompletedDate: function( date ) {
				return this.$functions.formatDate( date );
			},

			getStartLeadTime: function( date ) {
				let now = new Date();
				let then = new Date( date );
				let diff = Math.abs( now - then );
				let ceil = Math.ceil( diff  / ( 1000 * 60 * 60 * 24 ) ) - 1;

				if ( ceil === 0 ) {
					return { 
						today: true,
						length: 0,
						future: now > then
					};
				}

				return { 
					length: this.$functions.daysSinceOrUntil( date ).toString().replace('-', ''),
					days: this.$functions.daysSinceOrUntil( date ).toString().replace('-', '') >= 2 ? 'days' : 'day',
					future: now > then
				};
			}
		}
	}
</script>

<style scoped lang="scss">
	.assessment-meta {
		span:not(:last-child) {
			&:after {
				content: ' - ';
				padding: 0 .33rem;
			}
		}
	}
</style>