<template>
    <section class="error bg-mono-light full-screen" v-if="Object.values( $functions.getJourney() ).length <= 0">
        <div class="container">
            <div class="row m-0 py-7 error-row align-items-center">
                <div class="error-header">
                    <h1 class="color-mono-darkest font-largest mb-5">Uh oh, there has been an error.</h1>           
                    <p class="font-reset weight-bold">You currently do not have a journey assigned to your profile. Speak to your consultant to resolve this.</p>
                    <p class="font-reset weight-bold mb-0">Alternatively, <a class="color-mono-darkest" href="mailto:support@kaluna.co.uk">get help</a>.</p>

                    <button @click="logout" class="mt-5 button button-ui bg-brand-accent color-mono-lightest">Log Out &rarr;</button>   
                </div>
            </div>
        </div>

        <I viewBox="0 0 819.13 590.44" class="theme-i fill-mono-lightest" />
    </section>
</template>

<script>
    import I from '@/assets/images/svgs/miop-i.svg?inline';

    export default {
        name: 'Error',

        components: {
            I
        },

        methods: {
            logout() {
                this.$store.commit( 'message', { type: 200, message: 'Logging you out...' } )

                setTimeout( () => {
                    this.$router.push( { path: '/login' } );
                    this.$store.dispatch( 'logout' );
                    delete this.axios.defaults.headers.common[ 'Authorization' ];
                }, 500 );
            }
        }
    }
</script>