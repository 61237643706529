<template>
	<nav v-show="true === isActive" class="main-nav bg-brand-accent" id="main-nav">
		<div class="row main-nav__row align-items-center">
			<div class="main-nav__header p-4">
				<div class="row no-gutters justify-content-between ">
					<div class="col-auto">
						<router-link :to="{ path: '/' }">
							<Logo class="main-nav__logo fill-mono-lightest" />
						</router-link>
					</div>

					<div class="col-auto">
						<button @click.prevent="$store.commit( 'navigation_toggle', false )"  class="button button-svg button-ui" aria-label="Close navigation" title="Close navigation">
							<Times viewBox="0 0 352 512" class="main-nav__close fill-mono-lightest" />
						</button>
					</div>
				</div>
			</div>

			<div class="container">
				<ul v-if="false !== routes" class="nbs">
					<li v-for="( route, index ) in routes" :key="index">
						<router-link :to="{ path: route.path }" v-html="route.name" />
					</li> 

					<li><button @click="logout">Logout &rarr;</button></li>
				</ul>
			</div>
		</div>

		<I viewBox="0 0 819.13 590.44" class="theme-i fill-mono-lightest" />
	</nav>
</template>

<script>
	import Logo from '@/assets/images/svgs/miop-logo.svg?inline';
	import Times from '@/assets/images/svgs/ui/times.svg?inline';
	import I from '@/assets/images/svgs/miop-i.svg?inline';

	export default {
		name: 'Navigation',

		data() {
			return {
				isActive: false,
				routes: false
			}
		},

		components: {
			Logo,
			Times,
			I
		},

		methods: {
			logout: function() {
				this.$store.commit( 'message', { type: 200, message: 'Logging you out...' } )

				setTimeout( () => {
					this.$router.push( { path: '/login' } );
					this.$store.dispatch( 'logout' );
					delete this.axios.defaults.headers.common[ 'Authorization' ];
				}, 500 );
			}
		},

		mounted() {
			this.routes = this.$router.options.routes.filter( route => typeof route.meta !== 'undefined' && route.meta.nav === true );

			window.addEventListener( 'keydown', ( event ) => {
				if ( 27 === event.keyCode ) {
					this.$store.commit( 'navigation_toggle', false );
				}
			} );
		},

		watch: {
			'$store.state.navigation': function() {
				this.isActive = this.$store.state.navigation;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.main-nav {
		position: fixed;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		li {
			&:not(:last-child) {
				margin-bottom: $spacer * 1.5;
			}
		}

		a,
		button {
			color: color( mono, lightest );
			font-size: font( lead );
			font-weight: weight( bold );
			height: auto;
			line-height: 1;
			padding: 0;
		}

		&__header {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
		}

		&__logo {
			width: 140px;

			::v-deep path {
				fill: inherit !important;
			}
		}

		&__close {
			margin-top: -5px;
		}

		&__row {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}
</style>