<template>
	<section v-if="Object.values( days ).length > 0" class="days-until bg-mono-lightest mb-7">
		<div class="days-until__inner">
			<span class="days-until__distance font-largest weight-bold mb-3" v-html="days.duration"></span>
			<span class="days-until__text weight-bold" v-html="text || 'days to go'"></span>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'DaysUntil',

		props: [ 'data', 'text' ],

		data() {
			return {
				days: []
			}
		},

		created() {
			this.days.duration = this.$functions.daysSinceOrUntil( this.data );
			this.days.duration = this.days.duration.toString().replace('-', '');
		}
	}
</script>

<style lang="scss" scoped>
	.days-until {
		text-align: center;

		&__inner {
			display: inline-block;
			vertical-align: middle;
		}

		span {
			display: block;
		}

		&__distance {
			background: color( mono, lightest );
			border-radius: 100%;
			width: 110px;
			height: 110px;
			line-height: 100px;
			border: 5px solid color( brand, secondary );
			box-shadow: 0 0 0 5px color( brand, accent );
			margin-top: - 55px;
			margin-left: auto;
			margin-right: auto;
		}
	}
</style>