<template>
	<section class="videos pt-5 pb-8 py-lg-8">
		<div class="container">
			<div class="row no-gutters justify-content-between mb-5 align-items-center">
				<h1 class="color-brand-accent font-largest weight-bold">Videos</h1>

				<div>
					<button @click.prevent="$router.go(-1)" class="button button-ui button-min bg-brand-secondary color-mono-darkest">Go back</button>
				</div>
			</div>		

			<div class="videos-row mt-5">
				<h2 class="color-brand-secondary font-lead mb-5">This weeks videos</h2>

				<div v-if="false !== videos || Object.values( videos ).length > 0">
					<div class="video mb-7" v-for="( video, index ) in videos" :key="index">
						<article>
							<div class="video-row mb-5 row no-gutters align-items-center justify-content-between">
								<div v-if="0 === index" class="col-12 video-img mb-5">
									<img :src="base + video.video.titleImage" alt="">
								</div>

								<div class="col-auto mb-3">
									<h3 class="font-reset color-brand-accent mb-2">
										<router-link v-if="typeof getStartLeadTime( video.startsOn ).today !== 'undefined' && true === getStartLeadTime( video.startsOn ).today" :to="{ name: 'Activity', params: { 'type': 'video', 'id': video.id  } }" v-html="getVideoAttr( video, 'title' )" />

										<span v-else v-html="getVideoAttr( video, 'title' )"></span>
									</h3>

									<span class="video-status" v-if="null !== video.completedAt"><Check /> <span class="weight-bold">Watched</span></span>

									<div v-else>
										<span v-if="typeof getStartLeadTime( video.startsOn ).today !== 'undefined' && true === getStartLeadTime( video.startsOn ).today" v-html="'Available now'"></span>

										<span v-else v-html="'Available in ' + getStartLeadTime( video.startsOn ).length + ' ' + getStartLeadTime( video.startsOn ).days"></span>
									</div>
								</div>

								<div class="col-auto mb-3">
									<router-link v-if="typeof getStartLeadTime( video.startsOn ).today !== 'undefined' && true === getStartLeadTime( video.startsOn ).today" class="button button-ui bg-brand-accent color-mono-lightest" :to="{ name: 'Activity', params: { 'type': 'video', 'id': video.id  } }" v-html="'View'" />

									<span class="button button-ui bg-brand-accent color-mono-lightest" data-disabled v-else v-html="'View'"></span>
								</div>
							</div>
						</article>
					</div>
				</div>

				<div v-if="typeof videos === 'undefined' || Object.values( videos ).length === 0">
					<p class="alert alert-info">There are no videos for you to watch today.</p>
				</div>
			</div>

			<div class="videos-row mt-7">
				<h2 class="color-brand-secondary font-lead mb-5">Previous videos</h2>

				<div class="video mb-7 row" v-if="false !== previous_videos || Object.values( previous_videos ).length > 0">
					<article class="col-6" v-for="( video, index ) in previous_videos" :key="index">
						<div class="video-row mb-5 row no-gutters align-items-center justify-content-between">
							<div v-if="typeof video.video.titleImage !== 'undefined' && null !== video.video.titleImage" class="col-12 video-img mb-5">
								<img :src="base + video.video.titleImage" alt="">
							</div>

							<div class="col-12 row no-gutters align-items-center">
								<div class="col mb-3">
									<h3 class="font-reset color-brand-accent mb-2">
										<router-link v-if="typeof getStartLeadTime( video.startsOn ).today !== 'undefined' && true === getStartLeadTime( video.startsOn ).today" :to="{ name: 'Activity', params: { 'type': 'video', 'id': video.id  } }" v-html="getVideoAttr( video, 'title' )" />

										<span v-else v-html="getVideoAttr( video, 'title' )"></span>
									</h3>

									<span class="video-status" v-if="null !== video.completedAt"><Check /> <span class="weight-bold">Watched</span></span>
								</div>

								<div class="col-auto mb-3">
									<router-link class="button button-ui bg-brand-accent color-mono-lightest" :to="{ name: 'Activity', params: { 'type': 'video', 'id': video.id  } }" v-html="'View'" />
								</div>
							</div>
						</div>
					</article>
				</div>

				<div v-if="typeof previous_videos === 'undefined'">
					<p class="alert alert-info">There are no previous videos for you to watch.</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import _ from 'lodash';
	import Check from '@/assets/images/svgs/ui/check.svg?inline';

	// import Flickity from 'vue-flickity';

	export default {
		name: 'Videos',
	
		data() {
			return {
				base: process.env.VUE_APP_BASE,
				videos: false,
				previous_videos: false,
				// flickityOptions: {
				// 	prevNextButtons: false,
				// 	pageDots: true,
				// 	wrapAround: true,
				// 	adaptiveHeight: true,
				// 	imagesLoaded: true
				// }
			}
		},

		components: {
			Check
		},

		mounted() {
			if ( false !== this.$store.getters.journey ) {
				this.axios.get( 
					'/activities?activity_type=videos&time_period=today',
					{
						auth: {
							username: this.$store.getters.user.firstName,
							password: this.$store.getters.token
						}
					},
				)
				.then( response => {
					if ( 200 === response.status ) {
						this.videos = response.data.activities;

						if ( typeof this.videos !== 'undefined' && Object.values( this.videos ).length > 0 ) {
							this.videos = this.videos.filter( video => ( this.$functions.daysSinceOrUntil( video.startsOn ) <= 7 && true === this.$functions.isFuture( video.startsOn ) ) || true === this.$functions.isToday( video.startsOn ) );
							this.videos = _.orderBy( this.videos, ( video ) => parseInt( this.$functions.daysSinceOrUntil( video.startsOn ).toString().replace('-', '') ) , 'asc' );
						}
					}
				} )
				.catch ( error => {
					console.log( error );
				} );

				this.axios.get( 
					'/activities?activity_type=videos&time_period=previous',
					{
						auth: {
							username: this.$store.getters.user.firstName,
							password: this.$store.getters.token
						}
					},
				)
				.then( response => {
					if ( 200 === response.status ) {
						this.previous_videos = response.data.activities;
					}
				} )
				.catch ( error => {
					console.log( error );
				} );
			}
		},

		methods: {
			getVideoAttr: function( video, attr ) {
				return video[video.activityType][attr];
			},

			getStartLeadTime: function( date ) {
				let ceil = parseInt( this.$functions.daysSinceOrUntil( date ).toString().replace('-', '') );

				if ( ceil === 0 ) {
					return { 
						today: true,
						length: 0
					};
				}

				return { 
					length: ceil,
					days: ceil >= 2 ? 'days' : 'day'
				};
			}
		}
	}
</script>

<style lang="scss" scoped>
	.video-row {
		margin-bottom: -1.5rem;
	}

	[data-disabled] {
		opacity: 0.5;
		cursor: not-allowed;

		&:hover {
			box-shadow: none !important;
			color: color( mono, lightest ) !important;
		}
	}

	.video-status {
		color: color( state, pos );

		svg {
			width: 20px;
			height: auto;
			margin-right: .5rem;
			margin-top: -2.5px;
			fill: color( state, pos );
		}
	}
</style>