<template>
	<section v-if="false !== user" class="profile py-5 py-md-7">
		<div class="container">
			<div class="row no-gutters justify-content-between mb-5 align-items-center">
				<h1 class="color-brand-accent font-largest weight-bold">Profile</h1>

				<div>
					<button @click.prevent="$router.go(-1)" class="button button-ui button-min bg-brand-secondary color-mono-darkest">Go back</button>
				</div>
			</div>

			<div class="form-group">
				<label class="weight-bold">Name</label>
				<input type="text" readonly :value="user.firstName">
			</div>

			<div v-if="false !== journey" class="form-group">
				<label class="weight-bold">Journey</label>
				<input type="text" readonly :value="journey.title">
			</div>

			<div v-if="false !== journey" class="form-group">
				<label class="weight-bold">Hospital</label>
				<input type="text" readonly :value="journey.customer.name">
			</div>

			<button class="mt-5 weight-bold color-brand-accent" @click.prevent="$router.go(-1)">&larr; Back</button>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Profile',

		data() {
			return {
				user: false,
				journey: false
			}
		},

		mounted() {
			if ( false !== this.$store.getters.user ) {
				this.user = this.$store.getters.user;
			}

			if ( false !== this.$store.getters.journey ) {
				this.journey = this.$store.getters.journey;
			}
		}
	}
</script>