<template>
	<section class="privacy py-5 py-md-7">
		<div class="container">
			<button class="weight-bold mb-4" @click.prevent="$router.go(-1)">&larr; Go back</button>

			<h1 class="color-brand-accent weight-bold font-largest mb-5">Privacy Policy</h1>

			<p class="p1"><b>MIOP APP PRIVACY NOTICE</b></p>
			<p class="p3">MiOperation Ltd (“<b>MiOp</b>”, “<b>we</b>”) are committed to protecting and respecting your privacy.</p>
			<p class="p3">This privacy notice will provide you information about how we look after your personal data when you use the MiOp mobile application software (the “<b>App</b>”) and tell you about your privacy rights and how the law protects you.</p>

			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Consent to installation of the App</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Under data protection laws, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes, and your rights in relation to your personal data.<span class="Apple-converted-space"> </span>This information is provided in this privacy notice and it is important that you read that information.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3"><b>You can change your mind</b>.<span class="Apple-converted-space"> </span>You may change your mind at any time to our processing of your personal data, and withdraw your consent, by accessing the “settings” page of the App and choosing to delete your account, or contacting us by email at <span class="s1">hello@mioperation.com</span>.<span class="Apple-converted-space"> </span>This will not affect the lawfulness of any processing carried out before you withdraw your consent.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>About this privacy notice</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">This privacy notice (together with our end-user licence agreement (also called a EULA) as set out at www.miop.co.uk and any additional terms of use incorporated by reference into the EULA, together our<b> </b>“<b>Terms of Use</b>”) applies to your use of:</li>
					</ol>
				</li>
			</ol>
			<ul class="ul1">
				<li class="li3">The App, hosted on app.miop.co.uk (the “<b>App Site</b>”), once you have downloaded or streamed a copy of the App onto your mobile telephone or handheld device (“<b>Device</b>”).</li>
				<li class="li3">Any of the services accessible through the App (“<b>Services</b>”) unless the EULA states that a separate privacy policy applies to a particular Service, in which case that privacy policy only applies.</li>
			</ul>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">This privacy notice sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">The App is not intended for children and we do not knowingly collect data relating to children.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your personal data. This privacy notice supplements those other notices and is not intended to override them.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>About us</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">For the purpose of UK data protection laws, MiOperation Ltd is the controller and is therefore responsible for your personal data.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">If you have any questions, including any requests to exercise your legal rights, please contact us using the details set out below.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Our full contact details are:</li>
					</ol>
				</li>
			</ol>
			<p class="p5"><span class="s1">Company name</span>: MiOperation Ltd</p>
			<p class="p5"><span class="s1">Company number</span>: 13278181</p>
			<p class="p5"><span class="s1">Registered office</span>: 6 Dovehouse Close, Warwick, CV34 6LL</p>
			<p class="p5"><span class="s1">Email</span>: hello@mioperation.com</p>

			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>How we collect personal data</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Personal data means any information about an individual from which that person can be identified.<span class="Apple-converted-space"> </span>It does not include data where your identity has been removed or which not associated with or linked to your personal data (anonymous data).</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We may collect and process the following data about you:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li class="li3"><b>Information you give us (“Submitted Information”)</b>: This is information you give us about you by filling in forms on the App, or by corresponding with us (for example, by e-mail or chat).<span class="Apple-converted-space"> </span>It includes information you provide when you register to use the App, download or register the App, subscribe to any of our Services, search for an App or Service, and when you report a problem with an App, our Services, or any of our sites. If you contact us, we will keep a record of that correspondence. The information you give us may include your name, address, e-mail address and phone number, the Device's phone number, age, username, password and other registration information.</li>
				<li class="li3"><b>Information we receive from other sources.</b> <span class="Apple-converted-space"></span></li>
			</ol>
			<ul class="ul1">
				<li class="li3"><b>“Third Party Information - Practitioners”: </b>We will receive certain information from your medical practitioner (namely, name, date of birth, email address. and contact number) for the purpose of creating your user account for the App.<span class="Apple-converted-space"></span></li>
				<li class="li3"><b>“Third Party Information – Other Sources”: </b>We may also work closely with other third parties (including, for example, medical practices and practitioners, business partners, sub-contractors in technical and delivery services). We will notify you when we receive information about you from them and the purposes for which we intend to use that information.</li>
			</ul>
			<ol class="ol2">
				<li class="li3"><b>Information we collect about you and your device (automated information)</b>. Each time you use our App we will automatically collect the following information:</li>
			</ol>
			<ul class="ul1">
				<li class="li3">technical information, including push notification IDs, the type of mobile device you use, your mobile operating system, the type of mobile browser you use<b> </b>(<b>Device Information</b>);</li>
				<li class="li3">information stored on your Device, including contact information,<span class="Apple-converted-space"> </span>login information, photos, videos or other digital content (<b>Content</b> <b>Information</b>);</li>
				<li class="li3">details of your use of any of our App including, but not limited to traffic data and the resources that you access (<b>Log</b> <b>Information</b>).</li>
			</ul>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We may also collect, use and share <b>Aggregated</b> <b>Data</b> such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific App feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Cookies</b></span></li>
			</ol>
			<p class="p3">We use cookies to distinguish you from other users of the App, App Site, or Appstore. This helps us to provide you with a good experience when you use the App or browse any of the sites and also allows us to improve the App and Our sites. For detailed information on the cookies we use and the purposes for which we use them, see our cookie policy [<a href="http://www.miop.co.uk"><span class="s3">www.miop.co.uk</span></a>]</p>

			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>How we use your personal data</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We will only use your personal data when the law allows us to.<span class="Apple-converted-space"> </span>Most commonly, we will use your personal data in the following circumstances:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li class="li3">To provide you with access to the App and the Services.</li>
				<li class="li3">Where you have <b>consented</b> before the processing.</li>
				<li class="li3">Where we need to <b>perform a contract</b> we are about to enter or have entered with you. This includes the EULA.<span class="Apple-converted-space"></span></li>
				<li class="li3">Where it is necessary for our <b>legitimate interests</b> (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
				<li class="li3">To deal with and/or <b>respond to any enquiry or request made by you</b> prior to entering into any contract or agreement with us or because of such contract or agreement.
					<ol class="ol2">
						<li class="li3">Where we need to comply with a <b>legal or regulatory obligation</b>, including the prevention of crime.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">In addition, we will use your personal data for the following activities:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li class="li3">Submitted Information:<span class="Apple-converted-space"></span></li>
			</ol>
			<ul class="ul1">
				<li style="list-style-type: none;">
					<ul class="ul1">
						<li class="li3">To install the App.<span class="Apple-converted-space"></span></li>
						<li class="li3">To manage our relationship with you as a user of the App, including notifying you of changes to the App or any Services, for the purpose of providing you with continued access to the App and Services.</li>
					</ul>
				</li>
			</ul>
			<ol class="ol2">
				<li class="li3">Device information: we store the ‘User Agent’ from your browser so that we can distinguish between multiple devices for push notifications (for example, if you log in from both your mobile phone and on your iPad).</li>
				<li class="li3">Content Information: we use your login and contact information so that the App can display the relevant information to you.<span class="Apple-converted-space"> </span>Whilst we do not directly store photos, videos and other digital content, these items may be cached (temporarily stored) in order to increase the performance of the App.<span class="Apple-converted-space"></span></li>
				<li class="li3">Log information: we use this data for the purpose of logging (recording how the App is used), fixing bugs in the App, and for usage analysis purposes.</li>
				<li class="li3">Third Party Information - Practitioners: to register you as a user of the App.</li>
			</ol>
			<ol class="ol2">
				<li class="li3">Third Party Information – Other Sources: we will notify you if and when we receive information about you from them and the purposes for which we intend to use that information.</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We may associate any category of information with any other category of information and will treat the combined information as personal data in accordance with this privacy notice for as long as it is combined.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Where we process personal data on the basis of consent, this means processing your personal data where you have signified your agreement by a statement or clear opt-in to processing for a specific purpose. Consent will only be valid if it is a freely given, specific, informed and unambiguous indication of what you want. You can withdraw your consent at any time by contacting us.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Where we process personal data on the basis of a legitimate interest, as set out in this privacy notice, legitimate interest means the interest of our business in conducting and managing our business to enable us to give you the best service and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. <span class="Apple-converted-space"> </span>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Your personal data will not be used for the purposes of automated decision-making.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Disclosure of your information</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Your personal data <span class="s4">will only be disclosed to those of our </span>employees or workers that have a need for such access<span class="s4"> for the purpose for which it was collected.<span class="Apple-converted-space"> </span>Your personal data will not be </span>disclosed to any other individuals or other entities except in the following circumstances:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li class="li3">Your personal data will be shared with your medical practitioner to the extent necessary to provide you with access to the App and the Services, so that you can fully benefit from using the App and the Services.</li>
				<li class="li3"><span class="s4">Where it is necessary for the performance of our contract with you, including where you have asked us to do so or where we need to take steps to enforce any contract which may be entered into between us.</span></li>
				<li class="li3"><span class="s4">In order to enforce the terms of our EULA and other agreements or to investigate potential breaches.</span></li>
				<li class="li3"><span class="s4">Where we are under a legal duty to do so in order to comply with any legal or regulatory obligation or request.</span></li>
				<li class="li3"><span class="s4">On occasion, we need to hire other companies to help us to serve you better and in some of these cases we may need to share personal data that is necessary to perform tasks for us, such as IT/website/system/data administration support.</span></li>
				<li class="li3"><span class="s4">In order to protect the rights, property or safety of our business, our employees and workers, customers, suppliers and others. This includes exchanging information with our professional advisers and with other companies and organisations for the purposes of fraud prevention and credit risk reduction.</span></li>
				<li class="li3"><span class="s4">In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets.</span></li>
				<li class="li3">If we or substantially all of our assets are acquired by a third party, in which case personal data held by it about users of the App will be one of the transferred assets<span class="s4">.</span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We require all third parties that process personal data on our behalf to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Where we store your personal data</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We do not transfer your personal data outside the European Economic Area (EEA).<span class="Apple-converted-space"> </span>Our servers (hosted by Amazon Web Services) are located in the United Kingdom.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">All information you provide to us is stored on our secure servers (hosted by Amazon Web Services). Where we have given you (or where you have chosen) a password that enables you to access certain parts of the App, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to the App; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We will collect and store personal data on your Device using application data caches and browser web storage.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Data retention</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We typically retain personal data for a period of eight years after a user ceases using the App, in line with industry guidelines for the medical sector.<span class="Apple-converted-space"> </span>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">In some circumstances, you can ask us to delete your data: see section 10 below for further information.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Your rights</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">You have the right to:</li>
					</ol>
				</li>
			</ol>
			<ul class="ul1">
				<li class="li3"><b>Request access </b>to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data that we hold about you and to check that we are lawfully processing it.</li>
				<li class="li3"><b>Request correction </b>of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data that we hold about you corrected, although we may need to verify the accuracy of the new personal data that you provide to us.</li>
				<li class="li3"><b>Request erasure </b>of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your personal data unlawfully or where we are required to erase your personal data to comply with local law. Please note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
				<li class="li3"><b>Object to processing </b>of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. In some cases, we may demonstrate that we have compelling legitimate grounds to process your personal data which override your rights and freedoms.</li>
				<li class="li3"><b>Request restriction of processing </b>of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
				<li class="li3"><b>Request the transfer </b>of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
				<li class="li3"><b>Withdraw consent at any time </b>where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain Services to you. We will advise you if this is the case at the time you withdraw your consent. <span class="Apple-converted-space"></span></li>
			</ul>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">If you wish to exercise any of your above rights, please send a written request to us at the postal or email address listed in section 3 above.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">You will not have to pay a fee to access your personal data or to exercise any of your other rights. However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive.<span class="Apple-converted-space"> </span>Alternatively, we may refuse to comply with your request in these circumstances.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Other websites and links</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">The App may, from time to time, contain links to and from the websites of our partner networks and affiliates. Please note that these websites and any services that may be accessible through them have their own privacy policies and that we do not accept any responsibility or liability for these policies or for any personal data that may be collected through these websites or services.<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li3">Please check third party privacy policies before you submit any personal data to these websites or use their services.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Changes to this privacy notice</b></span></li>
			</ol>
			<p class="p5">Any changes we may make to our privacy notice in the future will be posted on this page and, where appropriate, notified to you [by e-mail <b>OR</b> when you next start the App]. The new terms may be displayed on-screen and you may be required to read and accept them to continue your use of the App or the Services.</p>

			<ol class="ol1">
				<li class="li3"><b></b><span class="s1"><b>Complaints</b></span></li>
			</ol>
			<p class="p5">You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (<a href="http://www.ico.org.uk"><span class="s1">www.ico.org.uk</span></a>). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>

			<button class="weight-bold" @click.prevent="$router.go(-1)">&larr; Go back</button>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'PrivacyPolicy',
	}
</script>