<template>
	<section v-if="false !== assessment && status === 'onboarding'" class="assessment-onboarding full-screen py-4 py-lg-7 bg-brand-accent">
		<div class="container">
			<div class="row no-gutters">
				<div class="col-12">
					<h1 class="font-largest color-brand-secondary mb-5" v-html="assessment.title"/>

					<p class="color-mono-lightest font-mid" v-if="typeof assessment.summary.body !== 'undefined' && '' !== assessment.summary.body" v-html="assessment.summary.body"></p>
				</div>

				<div class="col-12 mt-auto">
					<div class="row align-items-center justify-content-between">
						<div class="col-auto">
							<button @click.prevent="$router.go(-1)" class="weight-bold color-mono-lightest">&larr; Come back later</button>
						</div>

						<div class="col-auto">
							<button type="button" v-if="Object.values( assessment.questions ).length > 0" @click.prevent="start" class="button button-ui bg-brand-secondary color-mono-darkest">Start</button>
						</div>
					</div>	
				</div>
			</div>
		</div>
	</section>

	<section v-if="status === 'questions'" class="assessment-questions full-screen py-4 py-lg-7 bg-brand-accent">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h1 class="font-largest color-brand-secondary mb-5" v-html="assessment.title"/>

					<div ref="assessmentVideo" v-if="'video' === assessmentType" class="assessment-video mt-n2 mb-3">
						<div v-html="assessment.embedCode" />
					</div>

					<form @submit.prevent="submit" class="assessment-questions__roll mb-5">
						<article :key="index" v-for="(question, index) in assessment.questions">
							<div v-show="Object.values( question.options ).length > 0 && this.question === index" >
								<div class="question-header mb-5">
									<h2 class="color-mono-lightest">{{ question.title }}</h2>

									<p class="color-mono-lightest weight-bold font-mid" v-html="index + 1 + '/' + Object.values( assessment.questions ).length"></p>
								</div>

								<div class="form-group h-auto row no-gutters align-items-center color-mono-lightest" v-for="( option, index ) in question.options" :key="index">
									<input class="mr-3" :id="'input_' + question.id + '_' + index" v-model="answers[question.id]" type="radio"  :value="option.id" />
									<label class="mb-0" :for="'input_' + question.id + '_' + index" v-html="option.title"></label>
								</div>
							</div>
						</article>

						<article v-show="question === Object.values( assessment.questions ).length">
							<div class="question-header mb-7">
								<h2 class="color-mono-lightest">{{ question.title }}</h2>

								<p class="color-mono-lightest weight-bold font-mid">Well done, assessment complete!</p>

								<!-- score -->

								<div class="assessment-actions row align-items-center">
									<div class="col-auto">
										<button class="button button-ui bg-brand-secondary">Submit</button>
									</div>

									<div class="col-auto">
										<router-link class="color-mono-lightest" :to="{ name: 'Assessments' }">All assessments &rarr;</router-link>
									</div>
								</div>
							</div>
						</article>
					</form>
				</div>
				
				<div class="col-12 mt-auto">
					<div class="assessment-progress border-radius bg-mono-lightest">
						<div :style="'width: ' + calculateWidth + '%'" class="assessment-progress__bar bg-brand-secondary"></div>
					</div>
					
					<div class="assessment-navigation row h-auto justify-content-between align-items-center mt-5">
						<div class="col-auto">
							<button @click.prevent="assessmentNavigation('previous')" class="button button-ui bg-brand-secondary">Previous</button>
						</div>

						<div class="col-auto">
							<button class="color-mono-lightest weight-bold font-lead" @click.prevent="$router.go(-1)">Exit</button>
						</div>

						<div class="col-auto">
							<button :disabled="currentAnswerisBlank" @click.prevent="assessmentNavigation('next')" class="button button-ui bg-brand-secondary">Next</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section v-if="status === 'completed'" class="assessment-completed full-screen py-7 bg-brand-accent">
		<div class="container">
			<h1 class="font-largest color-brand-secondary mb-5" v-html="assessment.title"/>
			
			<p class="weight-bold color-mono-lightest">You completed <span v-html="assessment.title"></span> on <span v-html="getCompletedDate( data.completedAt )"></span>.</p>

			<h2 class="color-mono-lightest weight-bold">Your score: {{ assessment.score }}</h2>

			<div class="mt-7">
				<button @click.prevent="$router.go(-1)" class="weight-bold color-mono-lightest">&larr; Go back</button>
			</div>
		</div>
	</section>

</template>

<script>
	export default {
		name: 'Assessment',

		props: [ 'data' ],

		data() {
			return {
				assessment: false,
				assessmentType: 'normal',
				question: 0, // question index 
				answers: {},
				status: 'onboarding'
			}
		},

		mounted() {
			this.assessment = this.data[ this.data.activityType ];

			if ( typeof this.assessment.embedCode !== 'undefined' && "" !== this.assessment.embedCode ) {
				this.assessmentType = 'video';

			}

			if ( this.data.completedAt ) {
				this.status = 'completed';
			}
		},

		updated() {
			if ( typeof this.$refs.assessmentVideo !== 'undefined' && null !== this.$refs.assessmentVideo ) {
				this.$refs.assessmentVideo.querySelector('iframe').removeAttribute('width');
				this.$refs.assessmentVideo.querySelector('iframe').removeAttribute('height');
			}
		},

		computed: {
			calculateWidth: function() {
				let total = Object.values( this.assessment.questions ).length;
				let value = this.question;
				let percentage = ( value / total * 100 ).toFixed(0);

				return percentage;
			},

			currentAnswerisBlank: function() {
				if ( typeof this.assessment.questions[ this.question ] === 'undefined' ) {
					return true;
				}

				let currentQuestion = this.assessment.questions[ this.question ].id;
				return typeof this.answers[currentQuestion] === 'undefined';
			}
		},

		methods: {
			start: function() {
				this.status = 'questions';
			},

			getCompletedDate: function( date ) {
				date = new Date( date );
				let day = date.getDate();
				let month = date.getMonth() + 1;
				let year = date.getFullYear();
				let hour = date.getHours();
				let minute = String( date.getMinutes() ).length <= 1 ? '0' + date.getMinutes() : date.getMinutes();

				return '<strong>' + day + '.' + month + '.' + year + '</strong> at <strong>' + hour + ':' + minute + '</strong>';
			},

			assessmentNavigation: function( direction ) {
				let total = Object.values( this.assessment.questions ).length;

				if ( 'next' === direction ) {
					if ( this.question === total ) {
						this.question = total;
					} else {
						this.question++;
					}
				}

				if ( 'previous' === direction ) {
					if ( this.question === 0 ) {
						this.question = 0;
					} else {
						this.question--;
					}
				}
			},

			submit: function() {
				if ( Object.values( this.answers ).length <= 0 ) {
					return;
				}

				this.axios.post( 
					'/activities/' + this.$route.params.id + '/completions',
					{
						'responses': this.answers
					},
					{
						auth: {
							username: this.$store.getters.user.firstName,
							password: this.$store.getters.token
						}
					}
					).then( () => {
						this.$store.dispatch( 'journey_recache' )
						.then( () => {
								this.$store.commit( 'success', JSON.stringify( { 'title': 'Well done!', 'sub_title': 'You have completed the "' + this.assessment.title + '" assessment.', 'body': false, 'redirect': true } ) );
							}
						)
					}
					).catch( error => {
						console.log( error );
					} 
					);
				}
			}
		}
	</script>

	<style lang="scss" scoped>
		.assessment-progress {
			position: relative;
			height: 10px;
			display: block;
			overflow: hidden;

			&__bar {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
			}
		}

		.assessment-video ::v-deep(div) {
			padding: 0 !important;
		}
	</style>