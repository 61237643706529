<template>
	<div v-if="Object.keys( data ).length > 0" class="message bg-mono-lightest border-radius box-shadow__light px-4 py-md-3 py-4" id="message" :class="'type-' + data.type">
		<div class="row align-items-center justify-content-between">
			<div class="col-auto pr-0">
				<Info class="message-icon" />
			</div>

			<div class="col">
				<p class="message-title font-sub weight-bold mb-0" v-html="data.message"></p>
			</div>

			<div class="col-auto">
				<button @click="clear" class="blank message-clear font-largest weight-bold">&times;</button>
			</div>
		</div>
	</div>
</template>

<script>
	import Info from '@/assets/images/svgs/ui/info-circle.svg?inline';

	export default {
		name: 'Message',

		components: {
			Info
		},

		data() {
			return {
				data: []
			}
		},

		mounted() {
			if ( false !== this.$store.state.messages ) {
				if ( Object.keys( this.$store.state.messages ).length > 0 ) {
					this.data = this.$store.state.messages;
				} else {
					this.data = JSON.parse( this.$store.state.messages );
				}
			}
		},

		methods: {
			clear: function() {
				if ( document.getElementById('message') ) {
					document.getElementById('message').classList.add('fadeDown');
				}

				setTimeout( () => {
					this.$store.commit( 'message', false );
				}, 500 );
			}
		},

		watch: {
			'$store.state.messages': function( message ) {
				if ( false !== message ) {
					if ( typeof message !== 'string' ) {
						this.data = message;
					} else {
						this.data = JSON.parse( message );
					}
				} else {
					this.data = false;
				}

				if ( typeof this.data.selfClose !== 'undefined' && true === this.data.selfClose ) {
					setTimeout( () => {
						this.clear();
					}, 1500 );
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.message {
		position: fixed;
		z-index: 15;
		left: 5%;
		width: 90%;
		bottom: $spacer * 2;
		border: 1px solid transparent;

		@include media-breakpoint-up(md) {
			left: inherit;
			width: auto;
			right: $spacer * 2;
		}

		@include media-breakpoint-up(lg) {
			right: $spacer * 5;
			bottom: $spacer * 5;
		}

		&-title {
			line-height: 1.6;
		}

		&-clear {
			height: auto;
			line-height: 1;
			padding: 0;
			display: block;

			&:hover {
				color: color( brand, accent );
			}
		}

		&-icon {
			fill: color( mono, lighter );
			width: 30px;
			height: auto;
		}

		&.type-404,
		&.type-401,
		&.type-500 {
			box-shadow: 0 0 45px -15px rgba( color( state, neg ), 0.33 ); 
			border-color: rgba( color( state, neg ), 0.2 );

			.message-icon {
				fill: color( state, neg );
			}
		}

		&.type-200 {
			box-shadow: 0 0 45px -15px rgba( color( state, pos ), 0.5 ); 
			border-color: rgba( color( state, pos ), 0.33 );

			.message-icon {
				fill: color( state, pos );
			}
		}
	}
</style>