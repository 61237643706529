import { createStore } from 'vuex';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

export default createStore({
    state: {
        navigation: localStorage.getItem('navigation') || false,
        user: localStorage.getItem('user') || false,
        messages: localStorage.getItem('messages') || false,
        success: localStorage.getItem('success') || false,
        token: localStorage.getItem('token') || false,
        journey: localStorage.getItem('journey') || false,
        activities: localStorage.getItem('activities') || false,
        articles: localStorage.getItem('articles') || false,
        loading: localStorage.getItem('loading') || false,
        error: localStorage.getItem('error') || false,
        referrer: localStorage.getItem('referrer') || false,
    },
    mutations: {
        navigation_toggle( state, status ) {
            state.navigation = status;
            localStorage.setItem( 'navigation' , status );
        },
        auth_request(state){
            state.status = 'loading'
        },
        auth_success(state, token){
            state.status = 'success'
            state.token = token
            localStorage.setItem('token', token)
        },
        auth_error(state){
            state.status = 'error'
        },
        logout(state){
            state.status = ''
            state.user = ''
            state.token = ''
        },
        route_update(state, route) {
            localStorage.setItem('route', route);
        },
        message(state, message) {
            state.messages = message;
        },
        articles(state, articles) {
            state.articles = articles;
            localStorage.setItem('articles', JSON.stringify( articles ));
        },
        success(state, success) {
            state.success = success;
        },
        user_update(state, user) {
            state.user = JSON.stringify( user );
            localStorage.setItem('user', JSON.stringify( user ));
        },
        referrer_update(state, referrer) {
            state.referrer = referrer;
            localStorage.setItem('referrer', referrer);
        },
        journey_update(state, journey) {
            state.journey = JSON.stringify( journey );
            localStorage.setItem( 'journey', JSON.stringify( journey ) );

            this.commit( 'loading_status_update', false );
        },
        activities_update(state, activities) {
            state.activities = JSON.stringify( activities );
            localStorage.setItem('activities', JSON.stringify( activities ));
        },
        loading_status_update(state, loading) {
            state.loading = loading;
            localStorage.setItem( 'loading', loading );
        },
        error_status_update(state, error) {
            state.error = error;
            localStorage.setItem( 'error', error );
        },
    },
    actions: {
        logout({commit}){
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                commit('logout')
                commit('message', [])
                commit('user_update', [])
                commit('journey_update', [])
                localStorage.removeItem('journey')
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                resolve()
            })
        },

        /* eslint-disable no-unused-vars */
        addToLog({commit}, message) {
            // console.log( message );

            // add user creds to message to track user specific stuff.
            if ( Object.values( this.getters.user ).length > 0 && Object.values( this.getters.journey ).length > 0 ) {
                message = this.getters.user.firstName.trim() + ', journey ID: #' + this.getters.journey.id + ' - ' + message;
            }

            if ( typeof process.env.NODE_ENV !== 'undefined' ) {
                message = message + '. Connecting from env: ' + process.env.NODE_ENV + '.';
            }

            axios.post( 
                'logs/', 
                {
                    message: message,
                }
            )
        },

        journey_timestamp_check() {
            if ( false === ( Object.values( this.getters.user ).length > 0 && typeof this.getters.user.firstName !== 'undefined' ) ) {
                return false;
            }

            this.commit( 'message', { type: 200, message: 'Checking your journey for updates...', selfClose: true } );

            if ( Object.values( this.getters.user ).length > 0 ) {
                this.dispatch( 'addToLog', 'Checking user journey timestamp' );

                axios.get( 
                    '/journeys/timestamp',
                    {
                        auth: {
                            username: this.getters.user.firstName,
                            password: this.getters.token
                        }
                    }
                )
                .then( response => {
                    if ( 200 === response.status ) {
                        this.dispatch( 'addToLog', 'User journey has been found, checking timestamp.' );

                        // cache timestamp.
                        let updatedAt = response.data.updatedAt;

                        // console.log( updatedAt > this.getters.journey.updatedAt );
                        // console.log( updatedAt, this.getters.journey.updatedAt );

                        // if updated at timestamp is newer than the cached updatedAt.
                        if ( updatedAt > this.getters.journey.updatedAt ) {
                            this.dispatch( 'addToLog', 'User journey has been updated since the user last logged in, recaching.' );
                            this.dispatch( 'journey_recache' );
                            this.commit( 'message', { type: 200, message: 'Journey updated.', selfClose: true } );
                        } else {
                            this.dispatch( 'addToLog', 'User journey has been found, journey doesn\'t need to be recached.' );
                            this.commit( 'message', { type: 200, message: 'Your journey is up to date.', selfClose: true } );
                        }
                    }
                } )
                .catch( error => {
                    // console.log( error );
                    this.commit( 'message', { type: 401, message: 'Sorry, we were unable to update your journey.', selfClose: true } )
                    this.dispatch( 'addToLog', 'User journey has not been found.' );
                } ) 
            } else {
                this.dispatch( 'addToLog', 'User is logged out, so we don\'t need to check the timestamp' );

                this.commit( 'message', { type: 200, message: 'Your journey is up to date.', selfClose: true } );
            }
        },
        
        journey_recache() {
            this.commit( 'loading_status_update', true );

            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                if ( Object.values( this.getters.user ).length > 0 ) {
                    axios.get( 
                        '/journeys/current',
                        {
                            auth: {
                                username: this.getters.user.firstName,
                                password: this.getters.token
                            }
                        },
                    )
                    .then( response => {
                        /*
                        recache users journey if timestamp from server is newer
                         */
                        
                        if ( 200 === response.status ) {
                            if ( Object.values( response.data ).length <= 0 ) {
                                // check whether there's a future journey
                                axios.get( 
                                    '/journeys/current.json',
                                    {
                                        auth: {
                                            username: this.getters.user.firstName,
                                            password: this.getters.token
                                        }
                                    },
                                )
                                .then( response => {
                                    if ( 200 === response.status ) {
                                        if ( Object.values( response.data.futureJourneys ).length > 0 ) {
                                            let journeys = _.orderBy( response.data.futureJourneys, [ data => data.startsOn ], 'desc' );

                                            if ( Object.values( journeys ).length > 0 ) {
                                                this.commit( 'journey_update', journeys[0] );
                                            }
                                        }
                                    }
                                } );

                                if ( Object.values( this.getters.journey ).length <= 0 ) {
                                    let journey = {};
                                    this.commit( 'journey_update', journey );
                                    this.commit( 'error_status_update', true );
                                    this.commit( 'message', { type: 401, message: 'Sorry, you are yet to be assigned a journey, please speak to your health professional.' } );
                                }
                            } else {
                                // get all activity keys.
                                let keys = Object.keys( response.data.journey ).filter( key => null !== key.match(/Activities/) );
                                let activities = {};

                                Object.values( keys ).forEach( key => {
                                    if ( Object.values( response.data.journey[ key ] ).length <= 0 ) {
                                        return;
                                    }

                                    /*
                                    Loop through activities and start to format object
                                    */
                                   
                                    Object.values( response.data.journey[ key ] ).forEach( activity => {
                                        if ( typeof activities[ activity.activityType ] === 'undefined' ) {
                                            activities[ activity.activityType ] = [];
                                        } 

                                        // mimick the daysSinceOrUntil function...
                                        let hours = moment().diff( moment( activity.startsOn ), 'hours' ).toString().replaceAll( '-', '' ); 
                                        let days = Math.round( ( hours / 24 ) );
                                        let total_days = 0;
                                        let future = moment( activity.startsOn ).isAfter( moment() );

                                        // if it's in the past, but under 24hours ago, then it's "today"
                                        if ( false === future ) {
                                            if ( parseInt( hours ) < 24 ) {
                                                days = 0;
                                            }

                                            if ( hours <= days * 24 ) {
                                                days = days - 1;
                                            }

                                            total_days = -days;
                                        }

                                        // if it's in the future, but under 24hours to go, then it's "today"
                                        if ( true === future ) {
                                            if ( parseInt( days ) <= 0 && parseInt( hours ) < 24 ) {
                                                days = 1;
                                            }

                                            if ( hours >= days * 24 ) {
                                                days = days + 1
                                            }

                                            total_days = days;
                                        }

                                        // if it's an exercise session that is in the past, or over 7 days away, ignore it.
                                        if ( ( 'exercise_session' === activity.activityType || 'exercise_programme' === activity.activityType ) && ( total_days < 0 || total_days >= 7 ) ) {
                                            return;
                                        }

                                        // if it's an assessment, that is in the past and completedAt, or, an assessment that is over 14 days away.
                                        if ( 'assessment' === activity.activityType && ( total_days < 0 && null !== activity.completedAt || total_days > 14 ) ) {
                                            return;
                                        }

                                        // empty var for exists.
                                        let exists = false;

                                        // check for duplicate ids.
                                        if ( Object.values( activities[ activity.activityType ] ).length > 0 ) {

                                            // loop through cached activites.
                                            activities[ activity.activityType ].filter( act => act.id === activity.id ? exists = true : exists = false );
                                        }

                                        if ( true === exists ) {
                                            return;
                                        }

                                        // cache the activity.
                                        activities[ activity.activityType ].push( activity );
                                    } );
                                } );

                                let journey = response.data.journey;
                                journey.allActivities = activities;

                                // sanitise the object.
                                Object.values( keys ).forEach( key => {
                                    delete journey[ key ];
                                } );

                                delete journey[ 'adherenceLevel' ];
                                delete journey[ 'exerciseAdherenceLevel' ];
                                delete journey[ 'videos' ];
                                delete journey[ 'checklists' ];

                                this.commit( 'journey_update', journey );

                                /*
                                save auth tokens for other requests
                                 */
                                axios.defaults.headers.common['Authorization'] = response.config.headers.Authorization;

                                resolve();
                            }
                        }
                    } )
                    .catch( error => {
                        if ( typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' ) {
                            if ( 401 === error.response.status || 404 === error.response.status ) {
                                this.commit('message', { type: error.response.status, message: 'Sorry, there has been a problem fetching your journey' });

                                setTimeout( () => {
                                    this.dispatch( 'logout' );
                                    delete axios.defaults.headers.common[ 'Authorization' ];
                                }, 250 );

                                resolve();
                            }
                        }

                        this.commit( 'loading_status_update', false );

                        // if ( 500 === error.response.status ) {
                        //     this.journey = false;
                        //     this.commit('message', { type: error.response.status, message: 'Sorry, there has been a problem with the server' } );
                        // }
                    } );
                }
            })
        },
    },
    getters: {
        navigation: state => state.navigation,
        messages: state => state.messages,
        success: state => state.success,
        articles: state => typeof state.articles === 'string' ? JSON.parse( state.articles ) : state.articles || false,
        user: state => JSON.parse( state.user ) || false,
        referrer: state => state.referrer || false,
        journey: state => JSON.parse( state.journey ) || false,
        activities: state => JSON.parse( state.activities ) || false,
        token: state => state.token,
        loading: state => state.loading,
    }
})
