<template>
	<section class="privacy py-5 py-md-7">
		<div class="container">
			<button class="weight-bold mb-4" @click.prevent="$router.go(-1)">&larr; Go back</button>

			<h1 class="color-brand-accent weight-bold font-largest mb-5">Terms & Conditions</h1>

			<p class="p1"><b>END USER LICENCE</b></p>
			<p class="p3">PLEASE READ THIS END USER LICENCE CAREFULLY. THIS LICENCE MUST BE ACCEPTED <span class="s1">BEFORE</span> DOWNLOADING, INSTALLING AND/OR USING THIS APP. YOU SHOULD UNDERSTAND THAT BY DOWNLOADING, INSTALLING AND/OR USING THIS APP, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET OUT IN THIS END USER LICENCE.</p>
			<p class="p5">YOU MUST BE 18 OR OVER TO ACCEPT THIS END USER LICENCE AND TO INSTALL AND/OR USE THE MIOPERATION APP.<span class="Apple-converted-space"> </span>IF YOU ARE NOT 18 OR OVER, YOU MUST NOT DOWNLOAD, INSTALL OR USE THE MIOPERATION APP.</p>

			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Who we are and what this licence does</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">This end user licence (“<b>Licence</b>”) is a legally binding and enforceable agreement between you (“<b>you</b>”) and MiOperation Ltd (company number 13278181, whose registered office is at 6 Dovehouse Close, Warwick, CV34 6LL (“<b>MiOp</b>”, “<b>we</b>”, “<b>us</b>” “<b>our</b>”).<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">We license you to use:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li class="li5">the “MiOp” mobile application software (the “<b>App</b>”) and any updates or supplements to it;</li>
				<li class="li5">any related online and/or electronic documentation that we may make available to you (the “<b>Documentation</b>”); and</li>
				<li class="li5">the service you connect to via the App and the content we make available to you through it (the “<b>Services</b>”),</li>
			</ol>
			<p class="p8">as permitted in this Licence.</p>

			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">Your use of the App and the Services will also be governed by the following:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li style="list-style-type: none;">
							<ol class="ol2">
								<li class="li5">Our privacy policy as may be updated from time to time, which sets out the terms on which we may process any personal data we collect from you or that you provide to us via the App.</li>
								<li class="li5">Our cookies policy as may be updated from time to time, which sets out information about the cookies we use and how we use them when you access and use our Services (including the App).</li>
								<li class="li5">Any other separate terms of use, privacy policies and other terms and conditions we may notify you of, and may apply in relation to access and use of specific Services or other services or websites accessed via the App.</li>
							</ol>
						</li>
					</ol>
				</li>
			</ol>
			<p class="p7"><span class="Apple-converted-space"></span></p>

			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">The App is intended for use only by people who live in the United Kingdom.<span class="Apple-converted-space"> </span>If you access or attempt to access the App from any location outside the United Kingdom, we cannot guarantee you will be able to access the App or the Services will function correctly.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If you download the App via an appstore:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li class="li5">The ways in which you can use the App and Documentation may also be controlled by that appstore’s own rules and policies listed below, which may apply instead of this Licence where there are differences between the two:</li>
			</ol>
			<ul class="ul1">
				<li style="list-style-type: none;">
					<ul class="ul1">
						<li class="li9"><span class="s3">App Store (iOS):<span class="Apple-converted-space"> </span><a href="https://www.apple.com/legal/internet-services/"><span class="s4">https://www.apple.com/legal/internet-services/</span></a><span class="Apple-converted-space"></span></span></li>
						<li class="li9"><span class="s3">Google Play (Android): <a href="https://play.google.com/intl/en_us/about/play-terms/index.html"><span class="s4">https://play.google.com/intl/en_us/about/play-terms/index.html</span></a><span class="Apple-converted-space"></span></span></li>
					</ul>
				</li>
			</ul>
			<ol class="ol2">
				<li class="li5">As a minimum, the<i> </i>App requires a device with the following operating system:</li>
			</ol>
			<ul class="ul1">
				<li style="list-style-type: none;">
					<ul class="ul1">
						<li class="li5">iOS 11.0</li>
						<li class="li5">Android 4.4W (API Level 20)<span class="Apple-converted-space"></span></li>
					</ul>
				</li>
			</ul>
			<ol class="ol2">
				<li class="li5">The App may not operate correctly with all devices or operating systems and browsers. In order to operate correctly, the App requires you to use a device, operating system and browser that comply with the minimum requirements set out above.</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>The Services</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">The App enables you to access certain health and wellbeing information which your medical practitioner wishes to make available to you in connection with their treatment of you.<span class="Apple-converted-space"> </span>We are not able to offer you any advice relating to the information that you access through the App, and you should direct all questions concerning that information to your medical professional.<span class="Apple-converted-space"> </span>We will not be liable to you for any loss or damage incurred because of the advice given to you by your medical professional.<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Your privacy</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">Under data protection legislation, we are required to provide you with certain information including who we are, how we process your personal data and for what purposes and your rights in relation to your personal data and how to exercise them.<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">This information is provided in our App privacy notice which can be found within the App and it is important that you read that information.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Support for the App and how to tell us about problems</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If you want to learn more about the App or the Service or have any problems using them, please take a look at our support resources via the App.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If you think the App or the Services are faulty or misdescribed or wish to contact us for any other reason please contact our customer service team at hello@mioperation.com.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If we have to contact you, we will do so by email, by SMS or by pre-paid post, using the contact details you have provided to us.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>How you may use the App, including how many devices you may use it on</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">In return for you agreeing to abide by the terms of this Licence, we hereby grant to you a non-exclusive, non-transferable, revocable licence to use the App, the Service and the Documents in the United Kingdom on the terms and conditions set out in this Licence.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">You may:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li style="list-style-type: none;">
					<ol class="ol2">
						<li class="li5">Download or stream a single copy of the App and view, use and display the App and the Service on such devices for your personal purposes only;</li>
						<li class="li5">Use any Documentation to support your permitted use of the App and the Service.</li>
						<li class="li5">Provided you comply with paragraph 5 of this Licence, make one copy of the App and the Documentation for back-up purposes; and</li>
						<li class="li5">Receive and use any free supplementary software code or update of the App incorporating "patches" and corrections of errors as we may provide to you.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">We do not warrant that the App and the Service will be available at all times or that it will be fully functional or error free.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Licence restrictions and acceptable use<span class="Apple-converted-space"></span></b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">Except as expressly set out in this Licence or as permitted by any local law, you undertake:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li style="list-style-type: none;">
					<ol class="ol2">
						<li class="li5">Not to copy the App, Documentation or Services, except as part of the normal use of the App or where it is necessary for the purpose of back-up or operational security;</li>
						<li class="li5">Not to rent, lease, sub-license, loan, provide, or otherwise make available, the App or the Services in any form, in whole or in part to any person without prior written consent from us;</li>
						<li class="li5">Not to translate, merge, adapt, vary, alter or modify, the whole or any part of the App, Documentation or Services nor permit the App or the Services or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use the App and the Services on devices as permitted in this Licence;</li>
						<li class="li5">Not to disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App or the Services nor attempt to do any such things, except to the extent that such actions cannot be prohibited because they are necessary to decompile the App to obtain the information necessary to create an independent program that can be operated with the App or with another program (“<b>Permitted</b> <b>Objective</b>”), and provided that the information obtained by you during such activities:<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ul class="ul1">
				<li style="list-style-type: none;">
					<ul class="ul1">
						<li class="li5">is not disclosed or communicated without the Licensor's prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective; and</li>
						<li class="li5">is not used to create any software that is substantially similar in its expression to the App;</li>
						<li class="li5">is kept secure; and</li>
						<li class="li5">is used only for the Permitted Objective;</li>
					</ul>
				</li>
			</ul>
			<ol class="ol2">
				<li style="list-style-type: none;">
					<ol class="ol2">
						<li class="li5"><b></b>Not to provide or otherwise make available the App in whole or in part (including but not limited to program listings, object and source program listings, object code and source code), in any form to any person without prior written consent from us;<span class="Apple-converted-space"></span></li>
						<li class="li5"><b></b>To comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the App or any Service.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">You warrant that you will not, and will not encourage or authorise any third party to (whether directly or indirectly):<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li class="li5">Use or permit the use of the App or any Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this Licence, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the App, any Service or any operating system;</li>
				<li class="li5">Make any request or submission which is fictitious, fraudulent or abusive;</li>
				<li class="li5">Transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the App or any Service;</li>
				<li class="li5">Infringe our intellectual property rights or those of any third party in relation to your use of the App or any Service;</li>
				<li class="li5">Introduce any code, files, scripts, agents, or programs intended to do harm (including, for example, viruses, Trojan horses, worms, time bombs, and cancel bots), or other computer programming routines or code that may damage or detrimentally interfere with the App or any data or personal information maintained on or in connection with the App;</li>
				<li class="li5">Use the App or any Service in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;<span class="Apple-converted-space"></span></li>
				<li class="li5">Not collect or harvest any information or data from the App, any Service or our systems or attempt to decipher any transmissions to or from the servers running any Service; or</li>
				<li class="li5">Engage in any other conduct that is, or that we deem to be, in conflict with this Licence.</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If you download or stream the App onto any phone or other device not owned by you, you must have the owner's permission to do so. You will be responsible for complying with this Licence, whether or not you own the phone or other device.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">You agree to indemnify and defend us against all liabilities, costs and expenses (including all interest, penalties, legal costs and other reasonable professional costs and expenses), damages and losses suffered or incurred by us, arising out of or in connection with any breach by you of this Licence.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>You may not transfer the App to someone else</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">The App and the Services are only intended to be used by you.<span class="Apple-converted-space"> </span>If you permit any other person to use your account with us to access and use the App or the Services, you:</li>
						<li class="li5">Do so entirely at your own risk;</li>
						<li class="li5">Must not, in so doing, share your user identification code or password with such person;</li>
						<li class="li5">Are responsible for their access and use of the App and the Services as if it were your access and use; and</li>
						<li class="li5">Must make sure such persons are aware of this Licence and other applicable terms and conditions, and that they comply with them.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">You are responsible for protecting any data (including personal data) stored on your device by utilizing a strong password. We will not be liable to you for any loss or damage incurred by any third party’s access because of your failure to keep your user identification code or password confidential or your failure to use a secure password.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If you know or suspect that anyone other than you knows your user identification code or password to your login account, you must promptly reset your password to something only you know.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">We are giving you personally the right to use the App and the Service as set in this Licence.<span class="Apple-converted-space"> </span>You must not otherwise transfer the App or the Service to someone else, whether for money, for anything else or for free. If you sell any device on which the App is installed, you must remove the App from it.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Updates to the App and changes to the Service</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">From time to time we may automatically update the App and change the Service to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively, we may ask you to update the App for these reasons.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using the App and the Services.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">From time to time we may be required to temporarily suspend full or a part of the App and the Services without any notice in case of:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol2">
						<li class="li5">Unavoidable maintenance and repair of computer system (although where such maintenance and repair is scheduled we will endeavour to give you reasonable notice of the same);</li>
						<li class="li5">Communication failure of computer system;</li>
						<li class="li5">Force majeure such as earthquake, thunder, fire, power outage, war, riot, labour dispute, pandemic; and</li>
						<li class="li5">When we judge temporary suspension is unavoidable due to events beyond our control.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">Where it is necessary for us to temporarily suspend full or a part of the App and the Services we will, wherever possible, contact you to let you know and will take steps to minimise the effect of the suspension.<span class="Apple-converted-space"> </span>Provided we do this, we will not be liable to you or third parties for any loss or damage incurred by any of the items stated in the above paragraph 9.3.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>We are not responsible for other websites you link to</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">The App or any Service may contain links to other independent websites which are not provided by us. Such independent sites are not under our control, and we are not responsible for, and have not checked and approved their content or their privacy policies (if any).</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">You will need to make your own independent judgement about whether to use any such independent sites, including whether to buy any products or services offered by them.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Intellectual property rights</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">All intellectual property rights throughout the world, including rights in copyright, patents, database rights, trademarks and other intellectual property rights in any content on the App, the Documentation and/or the Services including any information, data, text, images, videos, interactive services, and other works in any format belong to us (or our licensors).<span class="Apple-converted-space"> </span>The rights in the App and the Services are licensed (not sold) to you.<span class="Apple-converted-space"></span></li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">You have no intellectual property rights in, or to, the App, the Documentation or the Services other than the right to use them in accordance with this Licence.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Technical data</b></span></li>
			</ol>
			<p class="p10">We may collect technical data about your device, in accordance with our App privacy notice (accessible within the App).<span class="Apple-converted-space"> </span>By using the App and Service, you agree to us collecting and using technical information about that device and related software, hardware and peripherals to improve our products and to provide any Service to you.</p>

			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Our responsibility for loss or damage suffered by you</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">The App is not a medical device and the Services are not medical services, and we do not provide any warranty with regard to and we are not liable for any medical diagnosis provided by your medical practitioner.<span class="Apple-converted-space"> </span>The App and the Services are provided for general information only.<span class="Apple-converted-space"> </span>They do not offer any advice on which you should rely.<span class="Apple-converted-space"> </span>You must obtain specialist advice from your medical practitioner before taking or refraining from any action based on information obtained through the App or the Services.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">The App and the Services may contain technical inaccuracies or typographical errors. We reserve the right at any time and without notice to make changes and improvements to the App and the Services.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">Although we make reasonable efforts to update them, the App and the Services are provided "as is" and, to the extent permitted by law, we make no representations, warranties or guarantees, whether express or implied (including but not limited to the implied warranties of satisfactory quality and fitness for a particular purpose), that the App and App Services are (a) accurate, complete or up-to-date; (b) meet your particular requirements or needs; or (c) access to, or use of, the same will be uninterrupted or completely secure. This is because we are completely reliant on third parties to provide accurate and correct data for the App.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">We cannot guarantee that the App and the Services will be free of viruses or represent the full functionality, accuracy or reliability of the materials.<span class="Apple-converted-space"> </span>You should use your own virus protection software (and ensure that it is regularly updated) when accessing and using the App, and you acknowledge that the introduction of threats or viruses may be because of circumstances that are not within our control. However, where we have not exercised reasonable skill and care, if defective digital content that we have supplied damages a device or digital content belonging to you, we will either repair the damage or pay you compensation save that we will not be liable for damage that you could have avoided by following our advice to apply an update to you free of charge or for damage which was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If we fail to comply with this Licence, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this Licence or our failing to use reasonable care and skill.<span class="Apple-converted-space"> </span>We are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted this Licence, both you and we knew it might happen.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">Nothing in this Licence excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, if you can show that: a) this was caused by the App and b) we failed to use reasonable skill and care to prevent this, or any other liability that cannot be excluded or limited under either the laws of England and Wales.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">We are not liable for business losses. The App is for your domestic and private use. We shall not in any circumstances be liable for any business losses arising under or in connection with this Licence for: loss of profits, sales, business, or revenue; business interruption; loss of anticipated savings; loss or corruption of data or information; loss of business opportunity, goodwill or reputation; or any indirect or consequential loss or damage.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">Please back-up content and data used with the App. We recommend that you back up any content and data used in connection with the App, to protect yourself in case of problems with the App or the Service.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">The App and the Services have not been developed to meet your individual requirements. Please check that the facilities and functions of the App and the Services (as described on the appstore site and in the Documentation) meet your requirements.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">12.10.We are not responsible for events outside our control. If our provision of the Services or support for the App or the Services is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this, we will not be liable for delays caused by the event.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Termination </b></span><b>
				</b></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">You may stop using the App and/or cancel your account at any time.<span class="Apple-converted-space"> </span>If you wish to cancel your account, you should contact your medical practitioner.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">We may end your rights to use the App and Services at any time by contacting you if you have broken this Licence in a serious way. If what you have done can be put right we will give you a reasonable opportunity to do so.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">If we end your rights to use the App and Services, or you cancel your App account:</li>
					</ol>
				</li>
			</ol>
			<ol class="ol2">
				<li class="li5">All rights granted to you under this Licence shall cease immediately;</li>
				<li class="li5">You must stop all activities authorised by this Licence, including your use of the App and any Services.</li>
				<li class="li5">You must delete or remove the App from all devices in your possession and immediately destroy all copies of the App which you have and confirm to us that you have done this.</li>
			</ol>
			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Changes to this Licence</b></span></li>
			</ol>
			<p class="p10">We may need to update or amend the terms and conditions set out in this Licence to reflect changes in law or best practice, to deal with additional features we introduce or otherwise to protect our business and/or the App.<span class="Apple-converted-space"> </span>We will communicate such changes to you at least thirty (30) days in advance by sending you an email with details of the change, or notifying you of a change when you next start the App. If you do not accept any notified changes to this Licence, you will not be permitted to continue to use the App or the Service.</p>

			<ol class="ol1">
				<li class="li5"><b></b><span class="s1"><b>Other important terms</b></span></li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5"><span class="s1">Assignment</span>.<b> </b>We may transfer our rights and obligations under this Licence to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5">You may only transfer your rights or your obligations under this Licence to another person if we agree in writing.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5"><span class="s1">No rights for third parties</span>.<b> </b>This Licence does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Agreement.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5"><span class="s1">If a court finds part of this contract illegal, the rest will continue in force</span>.<b><span class="Apple-converted-space"> </span></b>Each of the paragraphs of this Licence operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5"><span class="s1">Waiver and delay</span>.<b> </b>Even if we delay in enforcing this Licence, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under this Licence, or if we delay in taking steps against you in respect of your breaking this Licence, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</li>
					</ol>
				</li>
			</ol>
			<ol class="ol1">
				<li style="list-style-type: none;">
					<ol class="ol1">
						<li class="li5"><span class="s1">Which laws apply to this contract and where you may bring legal proceedings</span>.<b><span class="Apple-converted-space"> </span></b>This Licence, its subject matter and its formation (and any non-contractual disputes or claims) are governed by English and Welsh law and proceedings can be brought in the courts of England and Wales. If you live in Scotland, you can bring legal proceedings in respect of the products in either the Scottish or the English courts. If you live in Northern Ireland, you can bring legal proceedings in respect of the products in either the Northern Irish or the English courts.</li>
					</ol>
				</li>
			</ol>

			<button class="weight-bold" @click.prevent="$router.go(-1)">&larr; Go back</button>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Terms',
	}
</script>