<template>
	<section class="login full-screen bg-brand-secondary color-mono-darkest">
		<div class="container">
			<div class="row py-7 login-row">
				<div class="col-12">
					<h1 class="color-brand-accent font-largest">Forgot your password?</h1>			
					<p class="font-mid weight-bold">Follow the instructions below to reset your MiOP password.</p>
				</div>

				<div class="col-12 login-actions mt-auto">
					<div class="login-form mb-5">
						<form @submit.prevent>
							<div v-if="false === successfully_reset">
								<div class="form-group row h-auto no-gutters">
									<div class="order-1 col-12">
										<input placeholder="Enter your email address, or your MiOP code" required type="text" v-model="form.email">
									</div>

									<label class="col-12" for="email">
										<span>Please enter your email address, or your MiOP code provided to you.</span>
									</label>
								</div>

								<div v-if="false !== isEmailOrCode() && 'code' === isEmailOrCode()" class="form-group row h-auto no-gutters">
									<h2 class="my-5 font-mid">Create your MiOP password, remember to keep this safe.</h2>

									<div class="form-group row h-auto no-gutters">
										<p>Your password must be at least 8 characters long, and include at least one capital letter, at least one special character and at least one number.</p>

										<div class="mb-5">
											<ul class="password-status nbs row align-items-center mb-n3">
												<li class="col-auto weight-bold mb-3" id="chars"><component :is="passwordStatusIsTrue( 'characters' )" class="password-status__icon mr-3" /> Eight Characters</li>
												<li class="col-auto weight-bold mb-3" id="capital"><component :is="passwordStatusIsTrue( 'uppercase' )" class="password-status__icon mr-3" /> One Capital Letter</li>
												<li class="col-auto weight-bold mb-3" id="special-char"><component :is="passwordStatusIsTrue( 'hashtag' )" class="password-status__icon mr-3" /> One Special Character</li>
												<li class="col-auto weight-bold mb-3" id="special-char"><component :is="passwordStatusIsTrue( 'number' )" class="password-status__icon mr-3" /> One Number</li>
											</ul>
										</div>

										<div class="order-1 col-12">
											<input @input="passwordIsValid()" required minlength="8" type="password" v-model="form.password">
										</div>

										<label class="col-12" for="password">
											<span>Password</span>
										</label>
									</div>

									<div class="form-group row h-auto no-gutters">
										<div class="order-1 col-12">
											<input required :disabled="false === passwordIsValid()" type="password" id="password-confirmation" v-model="form.password_confirmation">
										</div>

										<label class="col-12" for="password-confirmation">
											<span>Confirm your password</span>
										</label>
									</div>
								</div>

								<div class="form-row">
									<div v-if="false === successfully_reset">
										<button v-if="false !== isEmailOrCode() && 'email' === isEmailOrCode()" @click.prevent="reset" class="button button-ui bg-brand-accent color-mono-lightest" type="submit" aria-label="Send reset email" title="Send reset email">Send reset email &rarr;</button>

										<button v-if="false !== isEmailOrCode() && 'code' === isEmailOrCode()" @click.prevent="submit_code" class="button button-ui bg-brand-accent color-mono-lightest" type="submit" aria-label="Reset password" title="Reset password">Reset password &rarr;</button>
									</div>
								</div>	
							</div>	

							<div v-if="true === successfully_reset" class="mt-5">
								<p><Check class="reset-success__icon" /> Your password was successfully reset, proceed to the login screen to log back in to MiOP.</p>
								<a class="button button-ui bg-brand-accent color-mono-lightest" href="/login">Proceed to login &rarr;</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<MiopI class="theme-i fill-mono-lightest" />
	</section>
</template>

<script>
	import MiopI from '@/assets/images/svgs/miop-i.svg?inline';
	import Check from '@/assets/images/svgs/ui/check.svg?inline';
	import Uppercase from '@/assets/images/svgs/ui/uppercase.svg?inline';
	import Hashtag from '@/assets/images/svgs/ui/hashtag.svg?inline';
	import Characters from '@/assets/images/svgs/ui/characters.svg?inline';
	import Number from '@/assets/images/svgs/ui/number.svg?inline';

	export default {
		name: 'ForgotPassword',

		components: {
			MiopI,
			/* eslint-disable vue/no-unused-components */
			Check,
			Uppercase,
			Hashtag,
			Characters,
			Number,
		},

		data() {
			return {
				form: [],
				password_required: false,
				token: false,
				user: false,
				successfully_reset: false,
			}
		},

		methods: {
			passwordStatusIsTrue( item = "" ) {
				// uppercase.
				if ( 'uppercase' === item ) {
					let uppercase_test = /[A-Z]/;

					if ( true === uppercase_test.test( this.form.password ) ) {
						return 'Check';
					} else {
						return 'Uppercase';
					}
				}

				// hashtag.
				if ( 'hashtag' === item ) {
					let special_char_test = /[@$!%*#?&]/;

					if ( true === special_char_test.test( this.form.password ) ) {
						return 'Check';
					} else {
						return 'Hashtag';
					}
				}

				// number.
				if ( 'number' === item ) {
					let number_test = /\d/;

					if ( true === number_test.test( this.form.password ) ) {
						return 'Check';
					} else {
						return 'Number';
					}
				}

				// 8 chars.
				if ( 'characters' === item ) {
					if ( typeof this.form.password !== 'undefined' && this.form.password.length >= 8 ) {
						return 'Check';
					} else {
						return 'Characters';
					}
				}
			},

			passwordIsValid() {
				// 8 chars, one uppercase letter, special char, and one number.
				let password_regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
				return password_regex.test( this.form.password );
			},

			passwordsMatch() {
				if ( typeof this.form.password === 'undefined' || typeof this.form.password_confirmation === 'undefined' ) {
					return false;
				}

				return this.form.password === this.form.password_confirmation;
			},

			isEmailOrCode() {
				if ( typeof this.form.email === 'undefined' ) {
					return false;
				}

				// empty type var.
				let type = false;

				// check for emails.
				let email = /\S+@\S+\.\S+/;		

				if ( true === email.test( this.form.email ) ) {
					type = 'email';
				}

				// check for code.
				let code = /^[A-Za-z0-9]{4}(-?)[A-Za-z0-9]{4}\1[A-Za-z0-9]{4}$/;

				if ( true === code.test( this.form.email ) ) {
					type = 'code';

				}

				return type;
			},

			submit_code() {
				/*
				dispatch reset token
				 */
				this.axios.patch( 
					'password_resets/' + this.form.email, 
					{
						'password': this.form.password,
						'password_confirmation': this.form.password_confirmation,
					},
					{
						auth: {
							username: this.user.firstName,
							password: this.token
						}
					}
				)
				.then( response => {
					if ( 201 === response.status || 200 === response.status ) {
						this.$store.commit( 'message', { type: 200, message: 'Your password was successfully reset' } );
						this.successfully_reset = true;
					}
				})
				.catch( () => {
					this.$store.commit( 'message', { type: 404, message: 'There has been an error resetting your password. Please check your token and passwords and try again' } )
				})
			},

			reset() {
				/*
				dispatch signup
				 */
				this.axios.post( 
					'password_resets', 
					{
						"email": this.form.email,
					},
					{
						auth: {
							username: this.user.firstName,
							password: this.token
						}
					}
				)
				.then( response => {
					console.log( response );
					if ( 201 === response.status || 200 === response.status ) {
						this.$store.commit( 'message', { type: 200, message: 'Check your email for a password reset email.' } )
					}
				})
				.catch( () => {
					this.$store.commit( 'message', { type: 404, message: 'There has been an error sending you a password reset email.' } )
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login {
		&-row {
			height: 100vh;
		}
	}

	.password-status__icon {
		width: auto;
		height: 20px;
		margin-top: -2.5px;
	}

	.reset-success__icon {
		width: auto;
		height: 30px;
		margin-top: -2.5px;
		margin-right: 1rem;
	}
</style>