<template>
	<header class="masthead p-4 p-lg-5 bg-mono-lightest">
		<div class="row justify-content-between align-items-center">
			<div class="col-auto">
				<router-link class="masthead-logo" :to="{ path: '/' }">
					<img src="/img/miop-v2.png" alt="MiOp logo" />
				</router-link>
			</div>

			<div class="col-auto">
				<button @click.prevent="$store.commit( 'navigation_toggle', true )" aria-label="Toggle navigation visibility" title="Toggle navigation visibility" class="masthead-bars button button-ui button-svg">
					<Bars class="fill-brand-accent" />
				</button>
			</div>
		</div>
	</header>
</template>

<script>
	import Bars from '@/assets/images/svgs/ui/bars.svg?inline';

	export default {
		name: 'Header',

		components: {
			Bars
		}
	}
</script>

<style lang="scss" scoped>
	.masthead {
		position: sticky;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		border-bottom: 1px solid color( mono, lighter );

		&-logo {
			width: 140px;

			img {
				max-height: 40px;
			}
		}

		&-bars {
			svg {
				width: 35px;
			}
		}
	}
</style>