<template>
	<section class="layout">
		<component v-if="false !== component" :is="component" :data="data" />

		<Error v-else />
	</section>
</template>

<script>
	export default {
		name: 'Layout',

		data() {
			return {
				data: false,
				component: false
			}
		},
		mounted() {
			if ( false !== this.$store.getters.journey ) {
				if ( Object.values( this.$store.getters.journey.allActivities[ this.$route.name.toLowerCase() ] ).length <= 0 ) {
					return;
				}

				let obj = this.$store.getters.journey.allActivities[ this.$route.name.toLowerCase() ].filter( activity => activity.id === JSON.parse( this.$route.params.id ) );

				this.data = obj.find( activity => activity.id === JSON.parse( this.$route.params.id ) );

				if ( typeof this.data === 'undefined' || false === this.data ) {
					return;
				}

				this.component = this.$functions.camelCase( this.data.activityType, true );
			}
		}
	}
</script>