<template>
	<section v-if="false !== data" class="video py-5 py-7">
		<div class="container">
			<h1 class="font-largest color-brand-accent mb-4 align-center" v-html="data.video.title"></h1>

			<div class="align-center font-mid" v-if="data.video.summary.body" v-html="data.video.summary.body"></div>

			<Embed class="mt-7" :data="data.video.embedCode" />

			<div class="mt-7 row justify-content-between">
				<button @click.prevent="$router.go(-1)" class="weight-bold color-brand-accent">&larr; Go back</button>

				<button class="button button-ui bg-brand-accent color-mono-lightest" @click.prevent="complete()">Complete</button>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Video',

		props: [ 'data' ],

		mounted() {
			// console.log( this.data );
		},

		methods: {
			getStartLeadTime: function() {
				let now = new Date();
				let then = new Date( this.data.startsOn );
				let diff = Math.abs( now - then );
				let ceil = Math.ceil( diff  / ( 1000 * 60 * 60 * 24 ) );

				return ceil - 1;
				// minus one, so that it's available at the *start* of the available day
			},

			complete: function() {
				this.axios.post( 
					'/activities/' + this.$route.params.id + '/completions',
					{
						'responses': []
					},
					{
						auth: {
							username: this.$store.getters.user.firstName,
							password: this.$store.getters.token
						}
					}
					).then( response => {
						if ( 200 === response.status || 201 === response.status ) {
							this.$store.dispatch( 'journey_recache' )
							.then( response => {
									console.log( response );

									this.$store.commit( 'success', JSON.stringify( { 'title': 'Well done!', 'sub_title': 'You have watched the "' + this.data.video.title + '" video.', 'body': false, 'redirect': true } ) );
								}
							)
						}						
					}
					).catch( error => {
						console.log( error );
					} 
				);
			}
		}
	}
</script>