<template>
	<section v-if="false !== exercise" class="exercise py-5 py-md-7">
		<div class="container">
			<div class="exercise-image mb-5">
				<img class="mx-auto" :src="base + getAttribute( exercise, 'titleImageUrl' )" alt="">
			</div>

			<h1 class="font-largest color-brand-accent mb-5 align-center" v-html="getAttribute(exercise, 'title')"></h1>

			<div v-if="Object.values( getAttribute( exercise, 'sessions' ) ).length > 0">
				<h2 class="font-mid weight-bold mb-5">Sessions:</h2>

				<flickity @init="setupFlickity" class="flickity" ref="flickity" :options="flickityOptions">
					<div class="checklist mb-5 carousel-cell" v-for="( session, index ) in getAttribute( exercise, 'sessions' )" :key="index">
						<article>
							<span class="font-reset weight-bold color-mono-light" v-html="'Day ' + session.day"></span>

							<div v-if="Object.values( session.sets ).length > 0" class="sets">
								<ul class="nbs mt-2">
									<li v-for="( set, index ) in session.sets" :key="index">
										<p class="font-reset weight-bold color-mono-darkest" v-html="set.exercise.title"></p>
									</li>
								</ul>
							</div>

							<!-- <router-link :to="{ name: 'Activity', params: { type: 'exercise_session', id: session.id } }" class="button button-ui bg-brand-accent color-mono-lightest">Start</router-link> -->
						</article>
					</div>
				</flickity>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'ExerciseProgramme',

		props: [ 'data' ],

		data() {
			return {
				base: process.env.VUE_APP_BASE,
				exercise: false,
				flickityOptions: {
					prevNextButtons: false,
					pageDots: true,
					wrapAround: true,
					adaptiveHeight: true,
					imagesLoaded: true
				}
			}
		},

		mounted() {
			this.exercise = this.data;
		},

		methods: {
			getAttribute: function( activity, attribute ) {
				return activity['exerciseProgramme'][ attribute ];
			},

			setupFlickity: function() {
				setTimeout( () => {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				}, 500 );

				this.$refs.flickity.on( 'bgLazyLoad', () => {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				});

				this.$refs.flickity.on( 'change', () => {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				});

				this.$refs.flickity.on( 'dragEnd', () => {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				});
			},
		}
	}
</script>