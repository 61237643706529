<template>
	<div v-if="typeof data !== 'undefined'">
		<Embed :data="data.exercise.embedCode" />

		<h3 class="mt-5 color-brand-accent font-lead" v-html="data.exercise.title"></h3>

		<p class="font-reset mt-3 mb-5" v-if="'' !== data.exercise.summary.body" v-html="data.exercise.summary.body"></p>

		<div v-if="'' !== data.instructions" class="mt-5">
			<h4 class="font-mid mb-1">Instructions:</h4>

			<p v-html="data.instructions"></p>
		</div>

		<div class="row py-3 mb-n3">
			<div class="col-auto mb-3">
				<div><strong>Sets:</strong></div>
				<p class="mb-0" v-html="data.sets"></p>
			</div>

			<div class="col-auto mb-3">
				<div><strong>Reps:</strong></div>
				<p class="mb-0" v-html="data.reps"></p>
			</div>

			<div class="col-auto mb-3">
				<div><strong>Rest:</strong></div>
				<p class="mb-0" v-html="data.rest"></p>
			</div>
		</div>

		<div class="set-actions row justify-content-between no-gutters align-items-center mt-5">
			<button @click.prevent="update( 'missed' )" class="order-1 order-xs-1 button button-ui bg-state-neg color-mono-lightest">Missed</button>
			<button @click.prevent="update( 'cancelled' )" class="order-2 order-xs-1 col-12 col-xs-auto mt-5 mt-xs-0 color-mono-darkest weight-bold font-reset">Come back later</button>
			<button @click.prevent="update( 'completed' )" class="order-1 order-xs-1 button button-ui bg-state-pos color-mono-lightest">Complete</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Set',
		props: [ 'data' ],

		methods: {
			update: function( state ) {
				this.$emit( 'set_update', state );
			}
		}
	}
</script>

<style lang="scss" scoped>
	article {
		position: relative;
		padding-bottom: 56.25%;

		&::v-deep iframe {
			position: absolute;
			height: 100%;
		}
	}
</style>