/* eslint-disable no-console */

import { register } from 'register-service-worker'
// import store from '@/store';

// if (process.env.NODE_ENV === 'production') {
register(
	`${process.env.BASE_URL}service-worker.js`, 
	{
		ready () {
			// store.dispatch( 'addToLog', 'Service worker, "ready" action triggered.' );
			// store.dispatch( 'journey_timestamp_check' );
		},
		registered () {
		},
		cached () {
		},
		updatefound () {
			// store.dispatch( 'addToLog', 'Service worker, "updatefound" action triggered.' );
			window.location.reload( true );
		},
		updated () {
			// store.dispatch( 'addToLog', 'Service worker, "updated" action triggered.' );
			window.location.reload( true );
		},
		offline () {
			// console.log('No internet connection found. App is running in offline mode.')
		},
		error () {
			// store.dispatch( 'addToLog', 'Service worker error encountered.' );
			// console.error('Error during service worker registration:', error)
		}
	}
)
// }