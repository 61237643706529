<template>
	<section></section>
</template>

<script>
	export default {
		name: 'Logout',

		mounted() {
			this.$store.commit( 'message', { type: 200, message: 'Logging you out...' } )

			setTimeout( () => {
				this.$router.push( { path: '/login' } );
				this.$store.dispatch( 'logout' );
				delete this.axios.defaults.headers.common[ 'Authorization' ];
			}, 500 );
		}
	}	
</script>