<template>
	<section class="checklists pt-5 pb-8 py-lg-8">
		<div class="container">
			<div class="row no-gutters justify-content-between mb-5 align-items-center">
				<h1 class="color-brand-accent font-largest weight-bold">Checklists</h1>

				<div>
					<button @click.prevent="$router.go(-1)" class="button button-ui button-min bg-brand-secondary color-mono-darkest">Go back</button>
				</div>
			</div>			

			<div class="checklists-row mt-5" v-if="false !== checklists && Object.values( checklists ).length > 0" >
				<flickity @init="setupFlickity" class="flickity" ref="flickity" :options="flickityOptions">
					<div class="checklist mb-5 carousel-cell" v-for="( checklist, index ) in checklists" :key="index">
						<article>
							<div class="row no-gutters align-items-center justify-content-between">
								<div class="col-12 checklist-img mb-5">
									<img :src="base + checklist.checklist.titleImageUrl" alt="">
								</div>

								<div class="col-auto">
									<h3 class="font-reset color-brand-accent mb-0">
										<router-link :to="{ name: 'Activity', params: { 'type': checklist.activityType, 'id': checklist.id  } }" v-html="getChecklistAttr( checklist, 'title' )" />
									</h3>
								</div>

								<div class="col-auto">
									<router-link class="button button-ui bg-brand-accent color-mono-lightest" :to="{ name: 'Activity', params: { 'type': checklist.activityType, 'id': checklist.id  } }" v-html="'View'" />
								</div>
							</div>
						</article>
					</div>
				</flickity>

				<div class="mt-7">
					<button @click.prevent="$router.go(-1)" class="weight-bold color-brand-accent">&larr; Go back</button>
				</div>
			</div>

			<div v-if="Object.values( checklists ).length <= 0 && false !== checklists">
				<p class="alert alert-danger">There are no checklists for you to complete.</p>
				<button @click.prevent="$router.go(-1)" class="weight-bold color-brand-accent">&larr; Go back</button>
			</div>
		</div>
	</section>
</template>

<script>
	import _ from 'lodash';
	import Flickity from 'vue-flickity';

	export default {
		name: 'Checklists',
		
		data() {
			return {
				base: process.env.VUE_APP_BASE,
				checklists: false,
				attribute: 'allActivites',
				flickityOptions: {
					prevNextButtons: true,
					pageDots: true,
					wrapAround: true,
					adaptiveHeight: true,
					imagesLoaded: true
				}
			}
		},

		components: {
			Flickity
		},

		mounted() {
			this.axios.get( 
				'/activities?activity_type=checklists',
				{
					auth: {
						username: this.$store.getters.user.firstName,
						password: this.$store.getters.token
					}
				},
			)
			.then( response => {
				if ( 200 === response.status ) {
					this.checklists = _.orderBy( response.data.activities, ( checklist ) => parseInt( this.getStartLeadTime( checklist.startsOn ).length ) , 'asc' )
				}
			} )
			.catch ( error => {
				console.log( error );
			} );
		},

		updated() {
			window.addEventListener( 'DOMContentLoaded', () => {
				if ( this.$refs.flickity ) {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				}
			} );
		},

		methods: {
			setupFlickity: function() {
				setTimeout( () => {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				}, 500 );

				this.$refs.flickity.on( 'bgLazyLoad', () => {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				});

				this.$refs.flickity.on( 'change', () => {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				});

				this.$refs.flickity.on( 'dragEnd', () => {
					this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
				});
			},

			getChecklistAttr: function( checklist, attr ) {
				return checklist[checklist.activityType][attr];
			},

			getStartLeadTime: function( date ) {
				let now = new Date();
				let then = new Date( date );
				let diff = Math.abs( now - then );
				let ceil = Math.ceil( diff  / (1000 * 60 * 60 * 24) ) - 1;

				if ( ceil === 0 ) {
					return { 
						today: true
					};
				}

				return { 
					length: ceil,
					days: ceil >= 2 ? 'days' : 'day'
				};
			}
		}
	}
</script>