const files = require.context('.', false, /\.vue$/);
const views = {};

files.keys().forEach( (key) => {
	if ( key === './index.js' ) {
		return;
	}

	views[ key.replace(/(\.\/|\.js)/g, '') ] = files(key).default
});

export default views