<template>
	<div v-if="Object.values( $functions.getJourney() ).length > 0 && true === started" class="journey-active">
		<section class="home pt-5 pb-8 py-lg-8 bg-brand-accent color-mono-lightest" id="home">
			<div class="container">
				<h1 class="mb-2">Hi <span v-html="$store.getters.user.firstName || false"></span></h1>
				<p class="font-mid weight-bold mb-0">Welcome to your MiOP journey.</p>
			</div>
		</section>

		<DaysUntil v-if="true === $functions.isToday( $functions.getJourney().dateOfOperation )" :data="$functions.getJourney().dateOfOperation" text="today is your surgery day, good luck!" />
		<DaysUntil v-if="false === $functions.isToday( $functions.getJourney().dateOfOperation ) && true === $functions.isFuture( $functions.getJourney().dateOfOperation )" :data="$functions.getJourney().dateOfOperation" text="day(s) until your surgery" />
		<DaysUntil v-if="false === $functions.isToday( $functions.getJourney().dateOfOperation ) && true === $functions.isPast( $functions.getJourney().dateOfOperation )" :data="$functions.getJourney().dateOfOperation" text="day(s) since your surgery" />

		<Activities v-if="null !== todaysActivities" colour="color-mono-darkest" background="bg-brand-accent" :pre="'New'" :title="'Today\'s to do list'" :attribute="'todaysActivities'" :data="todaysActivities" />

		<Activities v-if="null !== previousActivities" colour="color-mono-darkest" background="bg-brand-secondary" :pre="'New'" :title="'Overdue tasks'" :attribute="'todaysActivities'" :data="previousActivities" />

		<Shortcuts />
	</div>

	<div v-else-if="Object.values( $functions.getJourney() ).length > 0 && false === started" class="journey-inactive">
		<section class="home pt-5 pb-8 py-lg-8 bg-brand-accent color-mono-lightest" id="home">
			<div class="container">
				<h1 class="mb-2">Hi<span v-html="', ' + $store.getters.user.firstName || false"></span></h1>
				<p class="font-mid weight-bold mb-0">Welcome to your MiOP journey.</p>
			</div>
		</section>

		<DaysUntil :data="$functions.getJourney().startsOn" text="day(s) until your journey starts" />
	</div>
</template>

<script>
	export default {
		name: 'Home',

		data() {
			return {
				started: true,
				surgeryFuture: false,
				surgery: 0,
				journey: [],
				todaysActivities: null,
				previousActivities: null,
			}
		},

		mounted() {
			if ( this.$functions.daysSinceOrUntil( this.$functions.getJourney().startsOn ) > 0 ) {
				this.started = true;
			}

			// fetch the previous activities from the API.
			this.axios.get( 
				'activities?time_period=previous', 
				{
					auth: {
						username: this.$store.getters.user.firstName,
						password: this.$store.getters.token
					}
				}
			)
			.then( response => {
					if ( 200 === response.status ) {
						this.previousActivities = response.data.activities;

						let not_allowed = [ 'none', 'email', 'exercise_programme', 'exercise_session' ];

						this.previousActivities = this.previousActivities.filter( data => not_allowed.indexOf( data.activityType ) < 0 );
					}
				} 
			)
			.catch( () => {
					this.$store.commit( 'message', { type: 401, message: 'We were unable to fetch your previous activities' } )
				}
			)

			// fetch the todays activities from the API.
			this.axios.get( 
				'activities?time_period=today', 
				{
					auth: {
						username: this.$store.getters.user.firstName,
						password: this.$store.getters.token
					}
				}
			)
			.then( response => {
					if ( 200 === response.status ) {
						this.todaysActivities = response.data.activities;
					}
				} 
			)
			.catch( () => {
					this.$store.commit( 'message', { type: 401, message: 'We were unable to fetch your previous activities' } )
				}
			)
		},

		watch: {
			'$store.state.journey': function() {
				window.location.reload( true );
			}
		}
	}
</script>
