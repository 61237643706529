<template>
	<section class="login full-screen bg-brand-secondary color-mono-darkest">
		<div class="container">
			<div class="row py-7 login-row">
				<div class="col-12">
					<h1 class="color-brand-accent font-largest">Login</h1>			
					<p class="font-mid weight-bold">Welcome back, enter your email address and password, or-your MiOP code to get started.</p>
				</div>

				<div class="col-12 login-actions mt-auto">
					<div class="login-form mb-5">
						<form @submit.prevent="login">
							<div class="form-group row h-auto no-gutters">
								<div class="order-1 col-12">
									<input placeholder="Enter your email address, or your MiOP code" required type="text" v-model="form.email" autocomplete="email">
								</div>

								<label class="col-12" for="email">
									<span>Please enter your email address, or your MiOP code provided to you.</span>
								</label>
							</div>

							<div v-if="'email' === isEmailOrCode()" class="form-group row h-auto no-gutters">
								<div class="order-1 col-12">
									<input required type="password" v-model="form.password">
								</div>

								<label class="col-12" for="password">
									<span>Password</span>
								</label>
							</div>

							<div v-if="true === password_required" class="mb-5">
								<h2 class="my-5 font-mid">Create your MiOP password, remember to keep this safe.</h2>

								<div class="form-group row h-auto no-gutters">
									<p>Your password must be at least 8 characters long, and include at least one capital letter, at least one special character and at least one number.</p>

									<div class="mb-5">
										<ul class="password-status nbs row align-items-center mb-n3">
											<li class="col-auto weight-bold mb-3" id="chars"><component :is="passwordStatusIsTrue( 'characters' )" class="password-status__icon mr-3" /> Eight Characters</li>
											<li class="col-auto weight-bold mb-3" id="capital"><component :is="passwordStatusIsTrue( 'uppercase' )" class="password-status__icon mr-3" /> One Capital Letter</li>
											<li class="col-auto weight-bold mb-3" id="special-char"><component :is="passwordStatusIsTrue( 'hashtag' )" class="password-status__icon mr-3" /> One Special Character</li>
											<li class="col-auto weight-bold mb-3" id="special-char"><component :is="passwordStatusIsTrue( 'number' )" class="password-status__icon mr-3" /> One Number</li>
										</ul>
									</div>

									<div class="order-1 col-12">
										<div class="row no-gutters align-items-center">
											<div class="col-12 col-md">
												<input id="password" @input="passwordIsValid()" required minlength="8" type="password" v-model="form.password">
											</div>

											<div class="col-12 col-md">
												<button type="button" @click.prevent="toggle_pw_vis( 'password' )" class="font-small">Toggle password visibility</button>
											</div>
										</div>
									</div>

									<label class="col-12" for="password">
										<span>Password</span>
									</label>
								</div>

								<div class="form-group row h-auto no-gutters">
									<div class="order-1 col-12">
										<div class="row no-gutters align-items-center">
											<div class="col-12 col-md">
												<input required :disabled="false === passwordIsValid()" type="password" id="password-confirmation" v-model="form.password_confirmation">

											</div>

											<div class="col-12 col-md">
												<button type="button" @click.prevent="toggle_pw_vis( 'password-confirmation' )" class="font-small">Toggle password visibility</button>
											</div>
										</div>
									</div>

									<label class="col-12" for="password-confirmation">
										<span>Confirm your password</span>
									</label>
								</div>
							</div>

							<div v-if="false === isEmailOrCode() || 'email' === isEmailOrCode()" class="row h-auto align-items-center">
								<div class="col-auto">
									<button :disabled="false === ( 'email' === isEmailOrCode() )" @click="$event.target.innerHTML = 'Logging you in...'" class="button button-ui bg-brand-accent color-mono-lightest" type="submit" aria-label="Login to MiOP" title="Login to MiOP">Log in &rarr;</button>
								</div>	

								<div class="col-12 mt-5">
									<div class="row no-gutters">
										<router-link class="color-mono-darkest" :to="{ path: '/forgot-password' }">Forgotten your password?</router-link>

										<router-link class="ml-5 color-mono-darkest" :to="{ path: '/privacy-policy' }">Privacy Policy</router-link>

										<router-link class="ml-5 color-mono-darkest" :to="{ path: '/terms-conditions' }">Terms & Conditions</router-link>
									</div>
								</div>
							</div>

							<div v-if="'code' === isEmailOrCode() && false === password_required" class="row h-auto align-items-center">
								<div class="col-auto">
									<button @click.prevent="proceed" class="button button-ui bg-brand-accent color-mono-lightest" type="submit" aria-label="Proceed" title="Proceed">Proceed &rarr;</button>
								</div>	
							</div>

							<div v-if="'code' === isEmailOrCode() && true === password_required" class="row h-auto align-items-center">
								<div class="col-12 mb-5">
									<label class="weight-bold mb-3">Terms & Conditions</label>

									<div class="row">
										<div class="order-0 col-auto">
											<input class="mt-n1" value="0" id="terms_conditions" required type="checkbox" v-model="form.terms_conditions">
										</div>

										<label class="col-auto" for="terms_conditions">
											<span class="weight-bold">I agree to the terms and conditions set out <router-link class="color-mono-darkest text-underline" :to="{ path: '/terms-conditions' }">here</router-link>.</span>
										</label>
									</div>
								</div>

								<div class="col-auto">
									<button :disabled="false === passwordsMatch() || false === termsAccepted()" @click.prevent="complete" class="button button-ui bg-brand-accent color-mono-lightest" type="submit" aria-label="Complete registration" title="Complete registration">Complete registration &rarr;</button>
								</div>	
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<MiopI class="theme-i fill-mono-lightest" />
	</section>
</template>

<script>
	import MiopI from '@/assets/images/svgs/miop-i.svg?inline';
	import Check from '@/assets/images/svgs/ui/check.svg?inline';
	import Uppercase from '@/assets/images/svgs/ui/uppercase.svg?inline';
	import Hashtag from '@/assets/images/svgs/ui/hashtag.svg?inline';
	import Characters from '@/assets/images/svgs/ui/characters.svg?inline';
	import Number from '@/assets/images/svgs/ui/number.svg?inline';

	export default {
		name: 'Login',

		components: {
			MiopI,
			/* eslint-disable vue/no-unused-components */
			Check,
			Uppercase,
			Hashtag,
			Characters,
			Number,
		},

		data() {
			return {
				form: [],
				password_required: false,
				token: false,
				user: false
			}
		},

		methods: {
			toggle_pw_vis( id ) {
				if ( ! id ) {
					return false;
				}

				if ( document.getElementById(id) ) {
					let x = document.getElementById(id).getAttribute( 'type' );

					if (x === "password") {
						document.getElementById(id).setAttribute( 'type', "text");
					} else {
						document.getElementById(id).setAttribute( 'type', "password");
					}
				}
			},
			passwordStatusIsTrue( item = "" ) {
				// uppercase.
				if ( 'uppercase' === item ) {
					let uppercase_test = /[A-Z]/;

					if ( true === uppercase_test.test( this.form.password ) ) {
						return 'Check';
					} else {
						return 'Uppercase';
					}
				}

				// hashtag.
				if ( 'hashtag' === item ) {
					let special_char_test = /[@$!%*#?&]/;

					if ( true === special_char_test.test( this.form.password ) ) {
						return 'Check';
					} else {
						return 'Hashtag';
					}
				}

				// number.
				if ( 'number' === item ) {
					let number_test = /\d/;

					if ( true === number_test.test( this.form.password ) ) {
						return 'Check';
					} else {
						return 'Number';
					}
				}

				// 8 chars.
				if ( 'characters' === item ) {
					if ( typeof this.form.password !== 'undefined' && this.form.password.length >= 8 ) {
						return 'Check';
					} else {
						return 'Characters';
					}
				}
			},

			passwordIsValid() {
				// 8 chars, one uppercase letter, special char, and one number.
				let password_regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
				return password_regex.test( this.form.password );
			},

			passwordsMatch() {
				if ( typeof this.form.password === 'undefined' || typeof this.form.password_confirmation === 'undefined' ) {
					return false;
				}

				if ( this.form.password.length <= 3 ) {
					return false;
				}

				return this.form.password === this.form.password_confirmation;
			},

			termsAccepted() {
				if ( typeof this.form.terms_conditions === 'undefined' ) {
					return false;
				}

				return this.form.terms_conditions;
			},

			isEmailOrCode() {
				if ( typeof this.form.email === 'undefined' ) {
					return false;
				}

				// empty type var.
				let type = false;

				// check for emails.
				let email = /\S+@\S+\.\S+/;		

				if ( true === email.test( this.form.email ) ) {
					type = 'email';
				}

				// check for code.
				let code = /^[A-Za-z0-9]{4}(-?)[A-Za-z0-9]{4}\1[A-Za-z0-9]{4}$/;

				if ( true === code.test( this.form.email ) ) {
					type = 'code';

				}

				return type;
			},

			proceed: function() {
				if ( typeof this.form.email === 'undefined' || "" === this.form.email ) {
					return false;
				}

				/*
				dispatch register
				 */
				this.axios.post( 
					'registrations', 
					{
						"token": this.form.email
					}
				)
				.then( response => {
					if ( 200 === response.status ) {
						if ( "" !== response.token ) {
							this.password_required = true;
							this.token = response.data.token;
							this.user = response.data.profile;
						}
					}
				})
				.catch( () => {
					this.$store.commit( 'message', { type: 404, message: 'There has been an error validating your code. Contact your surgeon.' } )
				})
			},

			complete() {
				/*
				dispatch signup
				 */
				this.axios.post( 
					'password', 
					{
						"password": this.form.password,
						"password_confirmation": this.form.password_confirmation
					},
					{
						auth: {
							username: this.user.firstName,
							password: this.token
						}
					}
				)
				.then( response => {
					if ( 200 === response.status ) {
						this.$store.commit( 'message', { type: 200, message: 'Registration complete, we are now logging you in.' } );
						
						// save new stuff.
						const token = response.data.token;
						const user = response.data.profile;
						user.updatedAt = response.data.updatedAt;
						
						// commit auth success.
						this.$store.commit( 'user_update', user );

						// commit full auth success.
						this.$store.commit( 'auth_success', token, user );

						/*
						redirect to home
						 */
						setTimeout( () => {
							this.$store.dispatch( 'journey_recache' );
							this.$router.push( { path: '/' } );
						}, 500 );
					}
				})
				.catch( () => {
					this.$store.commit( 'message', { type: 404, message: 'There has been an error validating your code. Contact your surgeon.' } )
				})
			},

			login: function( event ) {
				if ( ! this.form.email && ! this.form.password ) {
					this.$store.commit( 'message', { type: 404, message: 'Please enter your email and password before attempting to login.' } )
				}

				let user = {
					'email': this.form.email,
					'password': this.form.password,
				};

				/*
				dispatch login
				 */
				this.axios.post( 
					'logins', 
					user, 
					{
						headers: {
							'Content-Type': 'application/json',
							'Access-Control-Allow-Origin': '*',
						}
					} 
				)
				.then( response => {
					if ( 200 === response.status ) {
						// save new stuff.
						const token = response.data.token;
						const user = response.data.profile;
						user.updatedAt = response.data.updatedAt;
						
						// commit auth success.
						this.$store.commit( 'user_update', user )

						// commit message.
						this.$store.commit( 'message', { type: 200, message: 'Logging you in...' } )

						event.target.querySelector('button').innerHTML = 'Redirecting...';
						event.target.querySelector('button').setAttribute( 'disabled', true );

						// commit full auth success.
						this.$store.commit( 'auth_success', token, user );

						/*
						redirect to home
						 */
						setTimeout( () => {
							this.$store.dispatch( 'journey_recache' );
							this.$router.push( { path: '/' } );
						}, 500 );
					}
				})
				.catch( () => {
						event.target.querySelector('button').innerHTML = "Try again &rarr;";

						this.$store.commit( 'message', { type: 404, message: 'Sorry, there has been a problem logging you in, please check your credentials.' } );

						this.$store.commit('auth_error');
					}
				)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login {
		&-row {
			height: 100vh;
		}
	}

	.password-status__icon {
		width: auto;
		height: 20px;
		margin-top: -2.5px;
	}

	.text-underline {
		text-decoration: underline;
	}
</style>