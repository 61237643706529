<template>
	<div v-if="typeof data !== 'undefined'">
		<article ref="embeddedVideo" class="embed" v-html="data"></article>
	</div>
</template>

<script>
	export default {
		name: 'Embed',
		props: [ 'data' ],

		mounted() {
			if ( typeof this.$refs.embeddedVideo !== 'undefined' ) {
				this.$refs.embeddedVideo.querySelector('iframe').removeAttribute('width');
				this.$refs.embeddedVideo.querySelector('iframe').removeAttribute('height');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.embed {
		position: relative;
		padding: 0;
	}

	.embed ::v-deep(div) {
		padding: 0 !important;
	}

	.embed ::v-deep(iframe) {
		position: relative !important;
	}
</style>