<template>
	<section class="education py-5 py-md-7">
		<div class="container">
			<div class="row no-gutters justify-content-between mb-5 align-items-center">
				<h1 class="color-brand-accent font-largest weight-bold">Articles</h1>

				<div>
					<button @click.prevent="$router.go(-1)" class="button button-ui button-min bg-brand-secondary color-mono-darkest">Go back</button>
				</div>
			</div>

			<div v-if="Object.values( articles ).length > 0" class="mt-5">
				<!-- <flickity @init="setupFlickity" class="flickity" ref="flickity" :options="flickityOptions"> -->
					<div class="carousel-cell" v-for="( article, index ) in articles" :key="index">
						<article class="mb-7">
							<div class="article-img mb-5">
								<img :src="base + article.article.headerImageUrl" alt="">
							</div>

							<h2 class="color-brand-accent weight-bold font-large mb-5">
								<router-link :to="{ name: 'Activity', params: { type: 'article', id: article.id } }" v-html="article.article.title"></router-link>
							</h2>

							<div class="article-body" v-html="article.article.summary.body"></div>

							<div class="article-actions mt-5">
								<div class="row no-gutters align-items-center">
									<div v-if="null !== article.completedAt" class="article-completed mr-5">
										<span class="video-status" ><Check /> <span class="weight-bold">Read</span></span>
									</div>		

									<div>
										<router-link v-if="null !== article.completedAt" class="button button-ui bg-brand-accent color-mono-lightest" :to="{ name: 'Activity', params: { type: 'article', id: article.id } }" >Read again</router-link>
										<router-link v-else class="button button-ui bg-brand-accent color-mono-lightest" :to="{ name: 'Activity', params: { type: 'article', id: article.id } }" >Read article</router-link>
									</div>	
								</div>
							</div>
						</article>
					</div>
					<!-- </flickity> -->
				</div>
			</div>
		</section>
	</template>

	<script>
	// import Flickity from 'vue-flickity';
	import Check from '@/assets/images/svgs/ui/check.svg?inline';

	export default {
		name: 'Articles',

		data() {
			return {
				base: process.env.VUE_APP_BASE,
				articles: false,
				view: null,
				// flickityOptions: {
				// 	prevNextButtons: false,
				// 	pageDots: true,
				// 	wrapAround: true,
				// 	adaptiveHeight: true,
				// 	imagesLoaded: true
				// }
			}
		},

		mounted() {
			this.articles = this.$store.getters.journey.allActivities.article;
		},

		components: {
			Check
		},

		methods: {
			// setupFlickity: function() {
			// 	setTimeout( () => {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	}, 500 );

			// 	this.$refs.flickity.on( 'bgLazyLoad', () => {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	});

			// 	this.$refs.flickity.on( 'change', () => {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	});

			// 	this.$refs.flickity.on( 'dragEnd', () => {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	});
			// },

			excerpt( words, length = 100 ) {
				words = words.substring( 0, length );
				return words.substr( 0, Math.min( words.length, words.lastIndexOf(" ") ) ) + '...';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.education-tile {
		border: 1px solid color( mono, light );
		width: 100%;

		svg {
			width: auto;
			height: 50px;
		}
	}

	article {
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-up(md) {
			max-width: 100%;
		}

		img {
			width: 100%;
		}

		.article-img {
			aspect-ratio: 16/9;
			overflow: hidden;
		}
	}

	.article-completed {
		color: color( state, pos );

		svg {
			width: 20px;
			height: auto;
			margin-right: .5rem;
			margin-top: -2.5px;
			fill: color( state, pos );
		}
	}
</style>