<template>
	<section v-if="null === level && false !== exercise" class="exercise-level full-screen bg-brand-accent fadeIn">
		<div class="row no-gutters align-items-center">
			<div class="container px-5 h-auto">
				<div class="exercise-level-col fadeUp align-center">					
					<div class="container container-sm">
						<p class="font-large weight-bold mb-5 color-mono-lightest" v-html="exerciseProgramme.title"></p>

						<p class="color-mono-lightest mb-0 align-left font-reset weight-normal" v-if="typeof exerciseProgramme.summary.body !== 'undefined' && '' !== exerciseProgramme.summary.body" v-html="exerciseProgramme.summary.body"></p>

						<hr class="mt-5 mb-5 mt-lg-7 mb-lg-7">
					</div>

					<div class="exercise-level-choice">
						<h1 class="font-large family-heading color-mono-lightest weight-bold mb-5 align-center mb-2">Select a level</h1>
						<p class="font-mid color-mono-lightest weight-normal mb-0">You can change this at any point</p>

						<div class="mt-6 mb-n5 row justify-content-lg-center">
							<div class="col-12 col-lg-auto">
								<button @click.prevent="level = 'beginner'" class="button button-ui bg-brand-secondary color-mono-darkest mb-5">Beginner</button>
							</div>
							
							<div class="col-12 col-lg-auto">
								<button @click.prevent="level = 'intermediate'" class="button button-ui bg-brand-secondary color-mono-darkest mb-5">Intermediate</button>
							</div>
							
							<div class="col-12 col-lg-auto">
								<button @click.prevent="level = 'advanced'" class="button button-ui bg-brand-secondary color-mono-darkest mb-5">Advanced</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<I viewBox="0 0 819.13 590.44" class="theme-i fill-mono-lightest" />
	</section>

	<section v-if="null !== level && false !== exercise" class="exercise py-5 py-md-7">
		<div class="container">
			<div v-if="Object.values( sets ).length > 0">
				<div v-if="false === active_set">
					<div class="div mb-7">
						<h1 class="color-brand-accent font-largest mb-3">Session plan</h1>

						<p class="font-mid">To start the <strong v-html="exerciseProgramme.title"></strong> exercise session, click below and complete all exercises as best you can, good luck!</p>

						<button v-if="false === completed" @click.prevent="start_session()" class="button button-ui button-min bg-brand-accent color-mono-lightest" v-html="'Start session'"></button>

						<div v-if="true === completed">
							<router-link class="button button-ui button-min bg-brand-secondary color-mono-darkest" :to="{ path: '/exercises' }">All exercises &rarr;</router-link>	
						</div>
					</div>

					<ul class="nbs">
						<li v-for="( set, index ) in sets" :key="index" class="mb-7">
							<div class="row align-items-center">
								<div class="col-12">
									<h3 v-if="typeof set.exercise.title !== 'undefined'" class="font-lead weight-bold mb-4" v-html="set.exercise.title"></h3>
								</div>

								<div class="col-auto pr-9">
									<p v-if="'' !== set.instructions" v-html="set.instructions"></p>
									
									<div class="row mb-n3">
										<div class="col-auto mb-3">
											<div><strong>Sets:</strong></div>
											<p class="mb-0" v-html="set.sets"></p>
										</div>

										<div class="col-auto mb-3">
											<div><strong>Reps:</strong></div>
											<p class="mb-0" v-html="set.reps"></p>
										</div>

										<div class="col-auto mb-3">
											<div><strong>Rest:</strong></div>
											<p class="mb-0" v-html="set.rest"></p>
										</div>
									</div>
								</div>

								<div class="col set-actions mt-4">
									<div class="row align-items-center mb-n3">
										<div v-if="'completed' === set.state || false !== responses && false !== isCompleted( set )" class="col-auto mb-3 set-state completed">
											<Check /> <span class="weight-bold">Completed</span>
										</div>
										<div v-if="'missed' === set.state" class="col-auto mb-3 set-state missed">
											<Times /> <span class="weight-bold">Missed</span>
										</div>
									</div>
								</div>
							</div>
						</li> 
					</ul>

					<div v-if="false === completed" class="mt-7">
						<h2 class="mb-3 color-brand-accent font-lead">Ready to exercise?</h2>

						<button @click.prevent="start_session()" class="button button-ui button-min bg-brand-accent color-mono-lightest" v-html="'Start session'"></button>
					</div>
				</div>

				<div v-if="false !== active_set">
					<Set v-on:set_update="set_updated" :data="active_set" />
				</div>
			</div>

			<div v-else class="alert alert-danger">There are no sets for this session.</div>
		</div> 
	</section>
</template>

<script>
	import Check from '@/assets/images/svgs/ui/check.svg?inline';
	import Times from '@/assets/images/svgs/ui/times.svg?inline';
	import I from '@/assets/images/svgs/miop-i.svg?inline';

	export default {
		name: 'ExerciseSession',

		props: [ 'data' ],

		components: {
			Check,
			Times,
			I
		},

		data() {
			return {
				exercise: false,
				sets: false,
				responses: false,
				active_set: false,
				level: null,
				completed: false
			}
		},

		mounted() {
			if ( typeof this.data === 'undefined' ) {
				return;
			} else {
				this.exercise = this.data;
				this.exerciseProgramme = this.exercise.exerciseProgramme;
				this.responses = this.exercise.responses;
			}
		},

		watch: {
			level: function() {
				if ( Object.values( this.exercise.exerciseSession.sets ).length > 0 ) {
					this.sets = {};

					this.exercise.exerciseSession.sets.forEach( set => {
						let keys = Object.keys( set ).filter( key => key.indexOf( this.level ) >= 0 );

						if ( Object.values( keys ).length > 0 ) {
							keys.forEach( item => {
								let key = item.replace( this.level, '' ).toLowerCase();

								if ( typeof this.sets[ set.id ] === 'undefined' ) {
									this.sets[ set.id ] = [];
								}

								this.sets[ set.id ][ key ] = set[ item ];
							} );
						}
					} );
				}
			}
		},

		methods: {
			isCompleted: function( set ) {
				let response = this.responses.find( response => set.exercise.id === response.exerciseSet[ this.level + 'Exercise' ].id && true === response.exerciseSet.completed );

				if ( typeof response === 'undefined' ) {
					response = false;
				}

				return response;
			},

			set_updated: function( state ) {
				this.active_set.state = state;

				let responses = {}; 				
				let max = ( Object.values( this.sets ).length - 1 );
				let current = Object.values( this.sets ).indexOf( this.active_set );

				if ( 'cancelled' === state ) {
					this.active_set = false;
				} else {
					if ( 'completed' === state ) {
						responses[ Object.keys( this.sets )[current] ] = '1';
					}			

					if ( 'missed' === state ) {
						responses[ Object.keys( this.sets )[current] ] = '0';
					}

					if ( current === max ) {
						this.completed = true;
						this.complete_session();
					}

					this.active_set = false;

					if ( current >= 0 ) {
						this.activate_set( Object.values( this.sets )[ current + 1 ] );
					}

					this.responses.filter( response => { 
						if ( false === response.exerciseSet.completed ) {
							return;
						}

						responses[ Object.keys( this.sets )[current] ] = '1';
					} );

					// axios mark completed
					this.axios.post( 
						'/activities/' + this.exercise.id + '/completions',
						{ 
							'responses': responses
						},
						{
							auth: {
								username: this.$store.getters.user.firstName,
								password: this.$store.getters.token
							}
						}
					).then( () => {
							this.$store.dispatch( 'journey_recache' );
						}
					)
					.catch( () => {
						this.active_set = false;
					} );
				}	
			},

			isLevel: function( level = null ) {
				return typeof this.exerciseProgramme[ level ] !== 'undefined' && true === this.exerciseProgramme[ level ];
			},

			getAttribute: function( attr ) {
				return this.exercise[ this.$functions.camelCase( this.data.activityType, false ) ][ attr ];
			},

			activate_set( set ) {
				if ( typeof set === 'undefined' ) {
					return false;
				}

				if ( this.isLevel('beginner') && typeof set.beginnerInstructions !== 'undefined' || null !== set.beginnerInstructions ) {
					set.description = set.beginnerInstructions;
				}

				if ( this.isLevel('intermediate') && typeof set.intermediateInstructions !== 'undefined' || null !== set.intermediateInstructions ) {
					set.description = set.intermediateInstructions;
				}

				if ( this.isLevel('advanced') && typeof set.advancedInstructions !== 'undefined' || null !== set.advancedInstructions ) {
					set.description = set.advancedInstructions;
				}

				this.active_set = set;
			},

			start_session: function() {
				this.activate_set( Object.values( this.sets )[0] );
			},

			complete_session: function() {
				this.$store.commit( 'success', JSON.stringify( { 'title': 'Well done!', 'sub_title': 'You have completed the session.', 'body': false, 'redirect': true } ) );
			}
		}
	}
</script>

<style lang="scss" scoped>
	hr {
		border-color: rgba( white, 0.33 );
	}

	.set-state {
		svg {
			width: 14px;
			height: auto;
			margin-top: -2.5px;
			margin-right: $spacer / 2;
		}

		&.completed {
			color: color( state, pos );

			svg {
				fill: color( state, pos );
			}
		}

		&.missed {
			color: color( state, neg );

			svg {
				fill: color( state, neg );
			}
		}
	}
</style>