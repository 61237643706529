import { createRouter, createMemoryHistory } from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Logout from '../views/Logout.vue'
import Layout from '../views/Layout.vue'
import Terms from '../views/Terms.vue'
import Education from '../views/Education.vue'
import Activity from '../views/Activity.vue'
import Articles from '../views/Articles.vue'
import Videos from '../views/Videos.vue'
import Profile from '../views/Profile.vue'
import Exercises from '../views/Exercises.vue'
import Assessments from '../views/Assessments.vue'
import Checklists from '../views/Checklists.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			nav: true
		}
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
		meta: {
			nav: true
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login 
	},
	{
		path: '/forgot-password',
		name: 'Password Reset',
		component: ForgotPassword
	},
	{
		path: '/privacy-policy',
		name: 'Privacy Policy',
		component: PrivacyPolicy
	},
	{
		path: '/logout',
		name: 'Logout',
		component: Logout
	},
	{
		path: '/education',
		name: 'Education',
		component: Education,
		meta: {
			nav: true,
			shortcut: true,
			icon: {
				'category': 'white',
				'file': 'education'
			}
		}
	},
	{
		path: '/articles',
		name: 'Articles',
		component: Articles
	},
	{
		path: '/videos',
		name: 'Videos',
		component: Videos
	},
	{
		path: '/activity/:type/:id',
		name: 'Activity',
		component: Activity
	},
	{
		path: '/exercises',
		name: 'Exercises',
		component: Exercises,
		meta: {
			nav: true,
			shortcut: true
		}
	},
	{
		path: '/checklists',
		name: 'Checklists',
		component: Checklists,
		meta: {
			nav: true,
			shortcut: true
		}
	},
	// {
	// 	path: '/progress',
	// 	name: 'Progress',
	// 	component: Layout,
	// 	meta: {
	// 		shortcut: true,
	// 		nav: true,
	// 		active: false
	// 	}
	// },
	{
		path: '/assessment/:id',
		name: 'Assessment',
		component: Layout,
	},
	{
		path: '/assessments/',
		name: 'Assessments',
		component: Assessments,
		meta: {
			nav: true,
			shortcut: true
		}
	},
	{
		path: '/exercise_programme/:id',
		name: 'ExerciseProgramme',
		component: Layout,
		meta: {
			name: 'Exercise Programme'
		}
	},
	{
		path: '/exercise_session/:id',
		name: 'Session',
		component: Layout,
		meta: {
			name: 'Exercise Session'
		}
	},
	{
		path: '/terms-conditions',
		name: 'Terms',
		component: Terms,
		meta: {
			name: 'Terms & Conditions'
		}
	},
];

const router = createRouter({
	history: createMemoryHistory( process.env.BASE_URL ),
	routes
})

router.beforeResolve( (to, from, next) => {
	document.title = typeof to.meta.name !== 'undefined' ? to.meta.name : to.name + ' | MiOPeration';
	window.scrollTo(0, 0);
	store.commit( 'message', false );
	store.commit( 'success', false );
	store.commit( 'navigation_toggle', false );
	store.commit( 'referrer_update', from.name );

	/*
	if we've just logged out
	 */
	
	if ( to.path === '/terms-conditions' || to.path === '/privacy-policy' ) {
		next();
		return;
	} 

	if ( false === store.getters.user ) {
		if ( '/' !== to.path && ( '/login' !== to.path && '/forgot-password' !== to.path ) ) {
			next('/login')
			return;
		}
	} else {
		/*
		if navigating to the login page & logged in, redirect to home
		 */
		if ( '/login' === to.path && ( false !== store.getters.user && Object.values( store.getters.user ).length > 0 ) ) {
			next('/')
			return;
		}
	}

	next();
})

export default router