<template>
    <Message />

    <!-- welcome screen if we're logged out -->
    <Welcome v-if="false === $functions.userIsLoggedIn()" />

    <!-- loading screen if we're loading -->
    <Loader v-show="true === this.$functions.isLoading()" />

    <!-- error screen if we've got an error -->
    <Error404 v-if="true === this.$functions.isError()" />

    <!-- <Error v-if="false === this.$functions.isLoading() && true === ( false === this.$functions.getJourney() && this.$functions.userIsLoggedIn() )" /> -->

    <!-- if logged in, show user the app -->
    <div v-if="true === $functions.userIsLoggedIn() && false !== $functions.getJourney()" :id="$route.name || 'body'">
        <Header/>
        <Navigation/>
        <main>
            <router-view/>
        </main>
        <Footer/>
        <Success/>
    </div>
</template>

<script>
    import moment from 'moment';
    
    export default {
        data() {
            return {
                user: this.$store.getters.user || false
            }
        },

        watch: {
            '$store.state.user': function( user ) {
                if ( false === user || Object.values( user ).length <= 0 || "[]" === user ) {
                    this.$store.dispatch( 'logout' );
                    this.user = false;
                    delete this.axios.defaults.headers.common[ 'Authorization' ];
                } else {
                    this.user = user;
                }
            }
        },

        mounted() {
            this.$functions.initApp();

            // this.$store.dispatch( 'journey_recache' );

            // notification token.
            if ( typeof localStorage.getItem('notificationToken') !== 'undefined' && ( false !== localStorage.getItem('notificationToken') || null !== localStorage.getItem('notificationToken') ) ) {

                if ( Object.values( this.$store.getters.user ).length > 0 ) {
                    if ( null !== localStorage.getItem('notificationToken') ) {
                        this.axios.post( 
                            '/notification_tokens', 
                            {
                                token: localStorage.getItem('notificationToken'),
                                source: navigator.userAgent
                            },
                            {
                                auth: {
                                    username: this.$store.getters.user.firstName,
                                    password: this.$store.getters.token
                                }
                            }
                        )
                    }
                }
            }

            // if user's details has been updated before "now". 
            if ( true === moment( this.$store.getters.user.updatedAt ).isBefore( moment() ) ) {
                // recache the user
                this.axios.get( 
                    'logins', 
                    {
                        auth: {
                            username: this.$store.getters.user.firstName,
                            password: this.$store.getters.token
                        }
                    }
                )
                .then( response => {
                    if ( 200 === response.status ) {
                        // save new stuff.
                        const token = response.data.token;
                        const user = response.data.profile;
                        user.updatedAt = response.data.updatedAt;
                        
                        // commit auth success.
                        this.$store.commit( 'user_update', user )

                        // commit full auth success.
                        this.$store.commit( 'auth_success', token, user );
                    }
                } )
                .catch( () => {
                    this.$store.dispatch( 'logout' );
                    this.$router.push( { path: '/login' } );
                    delete this.axios.defaults.headers.common[ 'Authorization' ];
                } )
            }
        },

        methods: {
            logout: function() {
                this.$store.commit( 'message', { type: 200, message: 'Logging you out...' } )

                setTimeout( () => {
                    this.$router.push( { path: '/login' } );
                    this.$store.dispatch( 'logout' );
                    delete this.axios.defaults.headers.common[ 'Authorization' ];
                }, 500 );
            }
        }
    }
</script>

<style lang="scss">
    body {
        background: white;
    }

    iframe#webWidget {
        position: fixed !important;
    }
</style>
