<template>
	<section v-if="false !== article" class="article py-5 py-md-7">
		<div class="container">
			<div class="article-img mb-5">
				<img :src="base + article.article.headerImageUrl" alt="">
			</div>

			<h1 class="color-brand-accent font-largest weight-bold mb-5" v-html="article.article.title"></h1>

			<div v-if="Object.values( article.article.blocks ).length > 0" class="article-blocks">
				<div v-for="( block, index ) in article.article.blocks" :key="index">
					<component :is="'Block' + $functions.titleCase( block.contents.name )" :data="block" />
				</div>
			</div>
			
			<div class="mt-7 row justify-content-between">
				<button @click.prevent="$router.go(-1)" class="weight-bold color-brand-accent">&larr; Go back</button>

				<button class="button button-ui bg-brand-accent color-mono-lightest" @click="complete">Mark as read</button>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Article',

		data() {
			return {
				base: process.env.VUE_APP_BASE,
				article: false
			}
		},

		mounted() {
			this.article = this.$store.getters.journey.allActivities.article.find( article => parseInt( this.$route.params.id ) === parseInt( article.id  ) ) || false;
		},

		methods: {
			complete( event ) {
				event.target.innerHTML = 'Please wait...';
				event.target.setAttribute( 'disabled', true );

				this.axios.post( 
					'/activities/' + this.article.id + '/completions',
					{
						'responses': []
					},
					{
						auth: {
							username: this.$store.getters.user.firstName,
							password: this.$store.getters.token
						}
					}
					).then( response => {
						if ( 200 === response.status || 201 == response.status ) {
							event.target.innerHTML = 'Successfully marked as read';
							event.target.removeAttribute( 'disabled' );

							this.$store.dispatch( 'journey_recache' )
							.then( response => {
								console.log( response );
								
								this.$store.commit( 'success', JSON.stringify( { 'title': 'Well done!', 'sub_title': 'You have read the "' + this.article.article.title + '" article.', 'body': false, 'redirect': true } ) );
								}
							)
						}
					}
					).catch( error => {
						console.log( error );
					} 
				);
			}
		}
	}
</script>