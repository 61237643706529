<template>	
	<section class="activities bg-mono-lightest py-7 border-top">
		<div class="container">
			<hr>

			<h2 class="mt-7 activities-title fw-bold mb-5" :class="typeof colour !== 'undefined' ? ' ' + colour : ' color-mono-darkest'" v-html="title"></h2>

			<ul v-if="Object.values( this.activities ).length > 0" class="activities-items nbs">
				<li v-for="( activity, index ) in activities" :key="activity.id" class="activities-item" :data-index="index">
					<ActivityComp :background="background" :pre="pre" :attribute="attribute" :data="activity" />
				</li>
			</ul>

			<p v-else-if="true === completed" class="alert alert-success">Well done! You have completed your activities.</p>
			<p v-else-if="true === empty" class="alert alert-info">There are no activities for you to complete today, come back tomorrow!</p>
			<p v-else class="alert alert-info">There are no activities for you.</p>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Activities',

		props: [ 'pre', 'title', 'data', 'attribute', 'colour', 'background' ],

		data() {
			return {
				activities: [],
				all_activities: [],
				completed_activities: [],
				completed: false,
				empty: false,
			}
		},

		mounted() {
			let not_allowed = [ 'none', 'email', 'exercise_programme' ];

			this.all_activities = this.data.filter( data => not_allowed.indexOf( data.activityType ) < 0 );

			// remove checklists if they have 1 checkbox answered.
			if ( Object.values( this.all_activities ).length > 0 ) {
				this.all_activities.map( activity => {
					if ( 'checklist' === activity.activityType ) {
						let trues = [];

						activity.responses.forEach( response => {
							if ( true === response.checklistItem.answer ) {
								trues.push( response );
							}
						} );

						if ( Object.values( trues ).length >= 1 ) {
							activity.completedAt = Date.now();
						}
					}
				} );
			}

			// if the activites from the API are 0.
			if ( Object.values( this.all_activities ).length === 0 ) {
				this.empty = true;
			}

			// count the amount of completed tasks.
			this.completed_activities = this.all_activities.filter( activity => null !== activity.completedAt );

			// cache incompleted activites.
			this.activities = this.all_activities.filter( activity => null === activity.completedAt )

			if ( Object.values( this.all_activities ).length > 0 && ( this.completed_activities.length === this.all_activities.length ) ) {
				this.completed = true;
			}

			// if we've received an empty flag from the API.
			if ( typeof this.data.empty !== 'undefined' && true === this.data.empty ) {
				this.empty = true;
				this.completed = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	ul.activities-items {
		margin-bottom: -1rem;
	}
</style>