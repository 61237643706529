<template>
	<section v-if="false !== checklist" class="checklist pt-0 pb-7 py-lg-7">
		<div class="container">
			<div class="checklist-image mb-3">
				<img :src="base + checklist.titleImageUrl" alt="">
			</div>

			<h1 class="font-largest color-brand-accent mb-5 align-center" v-html="checklist.title"></h1>

			<form @submit.prevent="submit" v-if="Object.values( checklist.items ).length > 0">
				<ul class="nbs mb-5">
					<li :key="index" v-for="( item, index ) in checklist.items">
						<div class="row align-items-center justify-content-between">
							<div class="col">
								<label :for="$functions.sanitizeString( item.title ) + '_' + item.position" class="weight-bold font-mid mb-0" v-html="item.title"></label>
							</div>

							<div class="col-auto">
								<input :checked="isComplete( item.id )" v-model="answers[ item.id ]" type="checkbox" :id="$functions.sanitizeString( item.title ) + '_' + item.position">
							</div>
						</div>
					</li>
				</ul>

				<div class="checklist-actions row align-items-center justify-content-between mt-7">
					<div class="col-auto">
						<button @click.prevent="$router.go(-1)" class="weight-bold color-brand-accent">&larr; Go back</button>
					</div>

					<div class="col-auto">
						<button class="button button-ui bg-brand-accent color-mono-lightest" type="submit">Update checklist</button>
					</div>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
	export default {
		name: "Checklist",

		props: [ 'data' ],

		data() {
			return {
				base: process.env.VUE_APP_BASE,
				checklist: false,
				responses: false,
				answers: {}
			}
		},

		mounted() {
			this.checklist = this.data[ this.data.activityType ];
			this.responses = this.data.responses || false;

			if ( false !== this.responses ) {
				Object.values( this.responses ).map( response => {
					this.answers[ response.checklistItem.id ] = response.checklistItem.answer;
				} ); 
			}
		},

		methods: {
			isComplete: function( id ) {
				if ( false === this.responses ) {
					return false;
				}	

				let response = this.responses.find( response => id === response.checklistItem.id && true === response.checklistItem.answer ) || false;

				return response;
			},

			submit: function() {
				if ( Object.values( this.answers ) <= 0 ) {
					return; 
				}

				// cache answers locally
				let answers = {};

				Object.keys( this.answers ).some( key => {
					if ( true === this.answers[ key ] ) {
						answers[ key ] = "1";
					}

					if ( false === answers[ key ] ) {
						answers[ key ] = "0";
					}
				} );

				this.axios.post( 
					'/activities/' + this.$route.params.id + '/completions',
					{ 
						'responses': answers
					},
					{
						auth: {
							username: this.$store.getters.user.firstName,
							password: this.$store.getters.token
						}
					}
				).then( () => {
						this.$store.dispatch( 'journey_recache' )
						.then( () => {
								this.$store.commit( 'success', JSON.stringify( { 'title': 'Well done!', 'sub_title': 'You have completed the "' + this.checklist.title + '" checklist.', 'body': false, 'redirect': true } ) );
							}
						)
					}
				).catch( () => {
						this.$store.commit( 'success', JSON.stringify( { 'title': 'Uh oh!', 'sub_title': 'There was a problem saving this checklist, please try again.', 'body': false } ) );
					} 
				);
			}
		}
	}
</script>

<style lang="scss" scoped>
	input[type="checkbox"] {
		border: 5px solid color( mono, mid ); 
	}

	ul li {
		&:not(:last-child) {
			margin-bottom: $spacer;
		}
	}

	button.button.button-ui {
		display: block;
		margin-left: auto;
	}
</style>