<template>
	<section v-if="false !== data" class="block block-contents">
		<div v-html="data.contents.body"></div>
	</section>
</template>

<script>
	export default {
		name: 'BlockContents',

		props: [ 'data' ],
	}
</script>