<template>
	<section class="exercises py-5 py-md-7">
		<div class="container">
			<div class="row no-gutters justify-content-between mb-5 align-items-center">
				<h1 class="color-brand-accent font-largest weight-bold">Exercises</h1>

				<div>
					<button @click.prevent="$router.go(-1)" class="button button-ui button-min bg-brand-secondary color-mono-darkest">Go back</button>
				</div>
			</div>	

			<div v-if="Object.values( exercises ).length > 0">
				<div class="checklist mb-7 carousel-cell" v-for="( exercise, index ) in exercises" :key="index">
					<article>
						<div class="exercise-row row align-items-center justify-content-between">
							<div class="col">
								<h3 class="font-mid color-mono-darkest" v-html="exercise.exerciseProgramme.title"></h3>

								<span v-if="typeof getStartLeadTime( exercise.startsOn ).today === 'undefined' || false === getStartLeadTime( exercise.startsOn ).today" v-html="'Available in ' + getStartLeadTime( exercise.startsOn ).length + ' ' + getStartLeadTime( exercise.startsOn ).days"></span>

								<span v-if="typeof getStartLeadTime( exercise.startsOn ).today !== 'undefined' || true === getStartLeadTime( exercise.startsOn ).today" v-html="'Available now'"></span>
							</div>

							<div class="col-auto">
								<router-link v-if="typeof getStartLeadTime( exercise.startsOn ).today !== 'undefined' || true === getStartLeadTime( exercise.startsOn ).today" class="button button-ui bg-brand-accent color-mono-lightest" :to="{ name: 'Activity', params: { 'type': 'exercise_session', 'id': exercise.id  } }" v-html="'Start session'" />
							</div>
						</div>
					</article>
				</div>
				
				<div class="mt-7">
					<button @click.prevent="$router.go(-1)" class="weight-bold color-brand-accent">&larr; Go back</button>
				</div>
			</div>

			<div v-else>
				<p class="alert alert-info">There are no exercises currently available for you to do.</p>
				<button @click.prevent="$router.go(-1)" class="mt-7 weight-bold color-brand-accent">&larr; Go back</button>
			</div>
		</div>
	</section>
</template>

<script>
	// import Flickity from 'vue-flickity';
	import _ from 'lodash';

	export default {
		name: 'Exercises',

		data() {
			return {
				base: process.env.VUE_APP_BASE,
				exercises: false,
				// flickityOptions: {
				// 	prevNextButtons: false,
				// 	pageDots: true,
				// 	wrapAround: true,
				// 	adaptiveHeight: true,
				// 	imagesLoaded: true
				// }
			}
		},

		components: {
			// Flickity
		},

		mounted() {
			if ( false !== this.$store.getters.journey && typeof this.$store.getters.journey.allActivities.exercise_session !== 'undefined' ) {
				this.exercises = this.$store.getters.journey.allActivities.exercise_session.filter( sess => null === sess.completedAt && ( this.$functions.daysSinceOrUntil( sess.startsOn ) <= 7 && true === this.$functions.isFuture( sess.startsOn ) ) );
				this.exercises = _.orderBy( this.exercises, ( data ) => parseInt( this.getStartLeadTime( data.startsOn ).length ), 'asc' );
			}
		},

		updated() {
			// window.addEventListener( 'DOMContentLoaded', () => {
			// 	if ( this.$refs.flickity ) {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	}
			// } );
		},

		methods: {
			// setupFlickity: function() {
			// 	setTimeout( () => {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	}, 500 );

			// 	this.$refs.flickity.on( 'bgLazyLoad', () => {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	});

			// 	this.$refs.flickity.on( 'change', () => {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	});

			// 	this.$refs.flickity.on( 'dragEnd', () => {
			// 		this.$refs.flickity.$flickity.element.querySelector('.flickity-viewport').style.height = this.$refs.flickity.$flickity.selectedElement.querySelector('article').clientHeight + 'px';
			// 	});
			// },
			
			getAttribute: function( activity, attribute ) {
				return activity['exerciseSession'][ attribute ];
			},

			getStartLeadTime: function( date ) {
				let ceil = parseInt( this.$functions.daysSinceOrUntil( date ).toString().replace('-', '') );

				if ( ceil === 0 ) {
					return { 
						today: true,
						length: 0
					};
				}

				return { 
					length: ceil,
					days: ceil >= 2 ? 'days' : 'day'
				};
			}
		}
	}
</script>

<style lang="scss" scoped>
	.exercise-image {
		aspect-ratio: 16/9;
		overflow: hidden;

		img {
			width: 100%;
		}
	}
</style>