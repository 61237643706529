<template>
	<section class="loader welcome bg-brand-accent color-mono-lightest">
		<div class="container">
			<div class="row m-0 py-7 welcome-row align-items-center justify-content-center">
				<div class="welcome-header">
					<h1 class="color-brand-secondary font-largest mb-9">Loading...</h1>			

					<vue-progress-bar class="progress"></vue-progress-bar>

					<p class="font-mid weight-bold mb-5">We are busy loading your journey, please wait.</p>

					<p class="font-mid weight-bold mb-0">If you're having problems, try <a href="#" @click.prevent="logout">logging out</a>.</p>
				</div>
			</div>
		</div>

		<MiopI viewBox="0 0 819.13 590.44" class="theme-i fill-mono-lightest" />
	</section>
</template>

<script>
	import MiopI from '@/assets/images/svgs/miop-i.svg?inline';
	
	export default {
		name: 'Loader',

		components: {
			MiopI
		},

		updated() {
			this.$Progress.start();
		},

		beforeUnmount() {
			this.$Progress.finish();
		},

		methods: {
			logout: function() {
				this.$store.commit( 'message', { type: 200, message: 'Logging you out...' } )

				setTimeout( () => {
					this.$router.push( { path: '/login' } );
					this.$store.dispatch( 'logout' );
					delete this.axios.defaults.headers.common[ 'Authorization' ];
				}, 500 );
			}
		}
	}
</script>

<style lang="scss" scoped>
	.welcome {
		height: 100vh;
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&-row {
			height: 100vh;
		}

		a {
			color: color( brand, secondary );
		}
	}

	.progress {
		top: 6rem !important;
	}

	.welcome-header {
		position: relative;
		z-index: 0;
	}

	.container {
		position: relative;
		z-index: 1;
	}

	.theme-i {
		z-index: 0;
	}
</style>