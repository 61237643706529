<template>
	<section class="support bg-mono-lightest pb-7">
		<div class="container align-center">
			<div class="support-pill bg-brand-tertiary py-4 px-5 border-radius mx-auto">
				<h4 class="font-reset weight-bold color-mono-darkest"><span>Need help?</span> <a class="color-mono-darkest" href="mailto:support@kaluna.co.uk?subject=MiOP Support" title="Contact Support">Contact Support &rarr;</a></h4>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Support'
	}
</script>

<style lang="scss" scoped>
	.support-pill {
		display: inline-block;
	
		h4 {
			margin-bottom: 0;
		}

		@include media-breakpoint-down(sm) {
			h4 {
				margin-bottom: - $spacer;
			}

			a,
			span {
				width: 100%;
				display: inline-block;
				margin-bottom: $spacer;
			}
		}
	}
</style>